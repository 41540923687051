import React, { Component } from 'react';
import { useState } from "react"
import AuthUser from './AuthUser';

import 'typeface-titillium-web';
import { SPIDReactButton } from '@dej611/spid-react-button'

import '@dej611/spid-react-button/dist/index.css'


export default function Login() {
    const { http, setToken } = AuthUser();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const submitForm = () => {
        // api call
        http.post('/login', { email: email, password: password }).then((res) => {
            setToken(res.data.user, res.data.access_token);
        })
    }

    return (
        <div className="row justify-content-center pt-5">
            <div className="col-sm-4">
                <div className="card p-4">
                    <h1 className="text-center mb-3">Login </h1>
                    <div className="form-group">
                        <label htmlFor="email">Email address:</label>
                        <input type="email" className="form-control" value={email}
                            onChange={e => setEmail(e.target.value)}
                            id="email" />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="pwd">Password:</label>
                        <input type="password" className="form-control" value={password}
                            onChange={e => setPassword(e.target.value)}
                            id="pwd" />
                    </div>
                    <button type="button" onClick={submitForm} className="btn btn-primary mt-4">Login</button>

                    {/** <SPIDReactButton url="/myLogin/{{idp}}"/> */}
                </div>
            </div>
        </div>
    )


}