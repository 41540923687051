import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import IpModal from '../../../components/shared-component/IpModal';


import axios from 'axios';
const links = [
    { url: '/app/home', name: 'Home', active: false },
    { url: '/app/ipaddress', name: 'indirizzi IP', active: false },
    { url: '/app/ipaddress/e', name: 'Gestione Indirizzi IP', active: true },
]



const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})


const ElencoIpAddresses = (props) => {
    const http = AuthUser().http;

   
    const  [username,setUsername]= useState({});
    const  [ipaddress,setipaddress]= useState([]);
    const  [showModal,setshowModal]= useState(false);
    const  [insertNew,setinsertNew]= useState(null);
    const  [editItem,seteditItem]= useState({id:0, ip:null});


    useEffect(()=> {
        http.get('/ipaddress', {}).then(response => {
            console.log(response);
            setipaddress(response.data );
            setUsername(response.data.userdata);
            
        });

    },[])

 
   const itemDelete = (id) => {
        http.delete(`/ipaddress/${id}`).then((response) => {
            if (response.data.success) {
             /*   Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });
                */
                setipaddress({ ipaddress: response.data.ips });
            }
        })
    }

  const  handleCloseModal = ()=>{
       
        setshowModal({showModal:false})
    }

 const   handleSave= (ip)=>{
        if(insertNew){
            http.post('/ipaddress',{ip}).then((response)=>{
                http.get('/ipaddress').then((response) => {
                    const m = response.data;
        
                    setipaddress({ ipaddress: m });
                })
            })
        } else {
            http.put(`/ipaddress/${editItem.id}`, {ip}).then((response)=>{
                http.get('/ipaddress').then((response) => {
                    const m = response.data;
        
                    setipaddress({ ipaddress: m });
                })
            })
        }
    }

        return (
            <div>
                <HeaderContent title="Gestione Indirizzi IP" subtitle="" links={links} />
                <div>
                    <section className="content">
                        {/* Default box */}
                        <div className="card">
                            <div className="card-header with-border">
                                <h3 className="card-title" />
                                <button variant="primary" onClick={() => {setshowModal(true); setinsertNew(true)}} >Nuovo Indirizzo IP</button>
                                <div className="card-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fa fa-minus" /></button>
                                    <button type="button" className="btn btn-box-tool" data-card-widget="remove" title="Remove">
                                        <i className="fa fa-times" /></button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Lista indirizzi IP</h3>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="card-body">

                                        <table id="tablemenu" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Indirizzo IP</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                 { ipaddress.map((m, i) => {
                                                    return (
                                                        <tr key={m.id}>
                                                            <td>{m.id}</td>
                                                            <td>{m.ip}</td>
                                                            
                                                            <td><a href="#" onClick={(e)=>{
                                                                e.preventDefault();

                                                              setshowModal(true);
                                                              setinsertNew(false);
                                                              seteditItem({id:m.id, ip:m.ip} );

                                                            }}><i className="fas fa-edit mr-3" /></a><a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                           
                                                                Swal.fire({
                                                                    title: 'Vuoi eliminare l\'indirizzo IP ?',
                                                                    showDenyButton: false,
                                                                    showCancelButton: true,
                                                                    confirmButtonText: `OK`,
                                                                    
                                                                  }).then((result) => {
                                                                   
                                                                    if (result.isConfirmed) {
                                                                        itemDelete(m.id);
                                                                    } 
                                                                  })
                                                                  
                                                            }}><i className="fas fa-trash" /></a></td>
                                                            
                                                        </tr>
                                                    )
                                                }
                                                
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.box-body */}
                            <div className="card-footer">
                            </div>
                            {/* /.box-footer*/}
                        </div>
                        {/* /.box */}
                    </section>
                    {/* /.content */}
                </div>

                {showModal && <IpModal show={showModal} item={editItem} closeFn={handleCloseModal} onSave={handleSave}/>}

            </div>
        )
    
}

export default ElencoIpAddresses;
