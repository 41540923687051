import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


type DomandaFields = {
    nome: string,
    cognome: string,
    cf: string,
    nascita: string,
    comunenascita: string,
    provincianascita: string,
    indirizzoresidenza: string,
    civicoresidenza: string,
    capresidenza: string,
    capnascita: string,

    comuneresidenza: string,
    provinciaresidenza: string,

    telefono: string,
    cellulare: string,
    pec: string,
    qualitadi: string,

    azienda: string,
    comunesede: string,
    indirizzosede: string,
    numerosede: string,
    cfazienda: string,
    piva: string,


    intestatoa: string,
    iban: string,

    dichiara1: boolean,
    dichiara2: boolean,

    allegati: []

}

const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({
    telefono: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    nome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cognome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cf: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascita: Yup.string().required('Obbligatorio'),
    comunenascita: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    azienda: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cfazienda: Yup.string().required('Obbligatorio').min(11, "inserire almeno 11 caratteri"),
    piva: Yup.string().required('Obbligatorio').min(11, "inserire almeno 11 caratteri"),


    provincianascita: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    indirizzoresidenza: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    comuneresidenza: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    civicoresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 1 caratteri"),
    provinciaresidenza: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    iban: Yup.string().required('Obbligatorio').matches(
        /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/,
        "Iban non conforme"
    ),

    civicosede: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    comunesede: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    indirizzosede: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    intestatoa: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),

    email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),

    dichiara1: Yup.boolean().isTrue(),

    dichiara2: Yup.boolean().isTrue(),

    allegati: Yup.array().of(

        Yup.object().shape({

            suap: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,


            documento: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,

            altro: Yup
                .mixed().required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    })

        })
    ),

})

const valoriIniziali: DomandaFields = {

    nome: "",
    cognome: "",
    cf: "",
    nascita: "",
    comunenascita: "",
    provincianascita: "",
    indirizzoresidenza: "",
    civicoresidenza: "",
    capresidenza: "",
    capnascita: "",
    comuneresidenza: "",
    provinciaresidenza: "",

    intestatoa: "",
    iban: "",

    telefono: "",
    cellulare: "",
    pec: "",
    qualitadi: "",

    azienda: "",
    comunesede: "",
    indirizzosede: "",
    numerosede: "",
    cfazienda: "",
    piva: "",


    dichiara1: false,
    dichiara2: false,


    allegati: []

    //*/
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Domandafornitori = () => {

    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);
        //   nw.print();
        //   nw.close();
    }

    return (
        <>
            <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={false}
                validateOnChange={false}

                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "106011");
                    fd.append('nome', "CentroEstivi");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });

                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });

                            window.location.href = "/esito?id=" + response.data.result;

                        }
                    }).catch((err) => {
                        console.log(err);

                        alert('Errore imprevisto. Provare a rinominare i file allegati senza utilizzare caratteri speciali');

                        document.getElementById('spinner').style.display = 'none';
                        document.getElementById('_conferma').style.display = 'inline';

                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">
                        <div className="form-group">
                            <div className="col-sm-12 col-sm-offset-1" >
                                <table>
                                    <tbody><tr>
                                        <td>
                                            <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                                <li className="active">
                                                    CENTRI ESTIVI 2023 – MODULO DOMANDA GESTORI
                                                </li>
                                            </ol>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>

                        {<div id="div_domanda">
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Anagrafici </div></div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf' id='cf' onChange={event => formik.setFieldValue('cf', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf' /> </small>

                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita" name="nascita" onChange={evt => {
                                            formik.setFieldValue('nascita', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita1`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita' label='Provincia di nascita' />

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='comuneresidenza' label='Comune di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='provinciaresidenza' label='Provincia di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza' label='Via di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza' label='Civico di residenza' />
                                </div>

                            </div>


                            <br />

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti</div></div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='email'>e-mail</label>
                                        <input type="text" className="form-control" name='email' id='email' onChange={event => formik.setFieldValue('email', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='email' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='pec'>PEC </label>
                                        <input type="text" className="form-control" name='pec' id='pec' onChange={event => formik.setFieldValue('pec', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='pec' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='telefono' label='telefono' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>In qualità di Rappresentante Legale di </div></div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='azienda' label='Denominazione Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='comunesede' label='Comune sede' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzosede' label='Via  sede' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicosede' label='Civico  sede' />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='cfazienda' label='Codice fiscale Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='piva' label='Partita Iva Ente' />
                                </div>

                            </div>

                            <p>A tal fine, consapevole che la falsità in atti e le dichiarazioni mendaci sono punite ai sensi del codice
                                penale e delle leggi speciali in materia e che, laddove dovesse emergere la non veridicità di quanto
                                dichiarato, si avrà la decadenza dai benefici eventualmente ottenuti ai sensi dell’art. 75 del d.P.R. n.
                                445 del 28 dicembre 2000 e l’applicazione di ogni altra sanzione prevista dalla legge, nella predetta
                                qualità, ai sensi e per gli effetti di cui agli artt. 46 e 47 del d.P.R. n. 445 del 28 dicembre 2000,

                            </p>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARA</div></div>
                            <p>
                                In nome e per conto dell’associazione/cooperativa/ente del Terzo Settore/ditta, o altro soggetto
                                giuridico comunque denominato che rappresenta, di aver preso visione degli Avvisi e delle condizioni
                                in essi contenute e di aver comunicato l’inizio dell’attività attraverso una Segnalazione certificata di
                                inizio Attività / Attività di Servizi temporanea (SCIA) resa, in forma di autocertificazione,
                                all’amministrazione comunale di Rende tramite piattaforma informatica SUAP, utilizzando il modello
                                E71.01 – SCIA -CENTRI ESTIVI, reperibile sulla modulistica Calabria Suap;
                            </p>
                            <div className="row">

                                <div className="form-check">

                                    <input id="dichiara1" type="checkbox" onChange={event => formik.setFieldValue('dichiara1', event.target.checked)} />

                                    <label htmlFor="dichiara1" >di avere la sede e operare nel Comune di Rende;;</label>

                                    {formik.errors.dichiara1 && <small className="error">*Obbligatorio</small>}

                                </div>

                                <div className="form-check">

                                    <input id="dichiara2" type="checkbox" onChange={event => formik.setFieldValue('dichiara2', event.target.checked)} />

                                    <label htmlFor="dichiara2" >di essere in regola con gli obblighi relativi al pagamento dei contributi previdenziali e assistenziali a
                                        favore dei propri lavoratori (se dovuti) ed accettare il vincolo di subordinare i pagamenti
                                        all’acquisizione Documento del Unico di Regolarità Contributiva;</label>

                                    {formik.errors.dichiara2 && <small className="error">*Obbligatorio</small>}
                                </div>
                                <br />




                            </div>
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>SI IMPEGNA</div></div>

                            <div className="form-check">

                                <input id="dichiarafatture" type="checkbox" onChange={event => formik.setFieldValue('dichiarafatture', event.target.checked)} />

                                <label htmlFor="dichiarafatture" > a trasmettere le ricevute/fatture quietanzate delle rette di frequenza rilasciate alle famiglie entro il 30/09/2023</label>

                                {formik.errors.dichiarafatture && <small className="error">*Obbligatorio</small>}
                            </div>
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>CHIEDE</div></div>

                            <p>
                                l’iscrizione al registro dei soggetti autorizzati all’erogazione dei servizi estivi che verrà pubblicato,

                                decorso il termine del 28/07/2023 alle ore 23:59, nella pagina istituzionale del Comune e sulla piattaforma on-
                                line, resa disponibile sul seguente link <strong>https://servizisociali.comune.rende.cs.it/ </strong> - sezione avvisi

                                ai quali le famiglie beneficiarie potranno rivolgersi;
                                Inoltre, chiede fin d’ora che la liquidazione delle fatture emesse per il rimborso dei contributi
                                assegnati ai soggetti beneficiari aventi sottoscritto delega a favore della organizzazione che
                                rappresenta avvenga mediante accredito sul conto corrente
                            </p>



                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='iban' label='IBAN' />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='intestatoa' label='Intestato a' />
                                </div>

                            </div>

                            <>
                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Allega</div></div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="documento" className="active">
                                        copia fotostatica di un documento di riconoscimento in corso di validità del legale
                                        rappresentante del soggetto candidato;
                                    </label>
                                    <input type="file" name="documento" id="documento" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.documento`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.documento`} /></small>

                                </div>


                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="suap" className="active">
                                        copia della ricevuta, rilasciata automaticamente dal portale SUAP e contenente il numero di
                                        protocollo assegnato alla SCIA comprovante l’avvenuta presentazione;
                                    </label>
                                    <input type="file" name="suap" id="suap" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.suap`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.suap`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label htmlFor="altro" className="active">Altro </label>
                                    <input type="file" name="altro" id="altro" className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-6" style={{}}

                                        onChange={e => (formik.setFieldValue(`allegati.${0}.altro`, e.target.files[0]))}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.altro`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label htmlFor="sostitutiva" className="active">Dichiarazione sostitutiva di certificazione <a target={'_blank'} href="/Allegato1-1.doc"> (file esempio)</a></label>
                                    <input type="file" name="sostitutiva" id="sostitutiva" className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-6" style={{}}

                                        onChange={e => (formik.setFieldValue(`allegati.${0}.sostitutiva`, e.target.files[0]))}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.sostitutiva`} /></small>

                                </div>

                                <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                                    <span className="visually-hidden">In elaborazione...</span>
                                    <div className="progress-bar" role="progressbar" />
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                        <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                            onClick={() => {
                                                stampa_id('root');
                                                console.log(formik.errors);
                                            }}

                                        >Anteprima</button>
                                        {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                       */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                            // alert('invio');

                                            formik.validateForm().then(async res => {
                                                const { ob } = await res;

                                                let b = Object.keys(res).length;

                                                //       console.log('res', b, res);
                                                //     console.log(b);

                                                if (res.figli !== undefined) {

                                                    b = b - 1;

                                                    for (let i = 0; i < res.figli.length; i++) {
                                                        // @ts-ignore 
                                                        b += Object.keys(res.figli[i]).length;
                                                    }

                                                    console.log(b);
                                                }

                                                if (res.allegati !== undefined) {
                                                    b = b - 1;
                                                    // @ts-ignore 
                                                    b += Object.keys(res.allegati[0]).length;

                                                }
                                                console.log(b);

                                                if (b > 0) {
                                                    Toast.fire({
                                                        title: 'ci sono ' + b + ' Errori nella compilazione',
                                                        icon: 'error'
                                                    });
                                                } else {
                                                    document.getElementById('spinner').style.display = 'inline';
                                                    document.getElementById('_conferma').style.display = 'none';
                                                }

                                            });

                                            console.log(formik.errors);

                                            formik.submitForm();

                                        }}>Invia</button></div>
                                </div>
                            </>

                        </div>}
                    </div>)}
            </Formik>

        </>
    )
}



export default Domandafornitori;