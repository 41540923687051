import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import IconSelector from '../../../components/shared-component/IconSelector';
import Swal from 'sweetalert2';
const links = [
    { url: '/app/home', name: 'Home', active: false },
    { url: '/app/menu/nuovomenu', name: 'Menù', active: false },
    { url: '/app/menu/editmenu', name: 'Nuovo Menù', active: true },
];

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const EditSubMenu = (props) => {
    const http = AuthUser().http;
    const params = useParams();
    console.log(params);
    var newinsert=1;
    if(params.id==0)newinsert=0;


   const  [states,setstates]= useState({
     id: params.id,
    name: null,
    function: null,
    owner: null,
    type: 'a',
    file: null,
    div_load: null,
    menu_id: params.menuId,
    submenu: null
});



useEffect(()=> {

    http.get(`submenu/${props.menuId}/${props.id}`).then((response) => {

        states.submenu = response.data ;
    })

    if (newinsert) {
        http.get(`submenu/${props.menuId}/${props.id}`).then((response) => {
            setstates({ ...response.data })
        })

    } 
    
 },[]);



 const   handleSave = () => {
        if (!newinsert) {
            http.post('submenu', { ...states }).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            }).catch((reason) => {

                Toast.fire({
                    title: reason.response.data.message,
                    icon: 'error'
                });
            })
        } else {
            http.put(`api/submenu/${states.id}`, states).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            }).catch((reason) => {
                Toast.fire({
                    title: reason.message,
                    icon: 'error'
                });
            })
        }
    }

const    handleInputChange = (evt) => {

        const s = states;
        s[evt.target.name] = evt.target.value;
        setstates(s);

    }

    const   onSelect = (selectedList, selectedItem) => {

        states.owner= selectedItem.id ;
    }



        return (
            <>
                <div>
                    <HeaderContent title={!newinsert ? 'Nuova Funzione' : 'Edit Funzione'} subtitle="navigazione" links={links} />

                    <div>

                        {/* Main content */}
                        <section className="content">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* general form elements */}
                                    <div className="card card-primary">
                                        <div className="card-header with-border">
                                     
                                        </div>
                                        {/* /.card-header */}
                                        {/* form start */}

                                        <input type="hidden" id="menu_id" name="menu_id" defaultValue="{{$idmenu}}" />
                                        <div className="card-body">
                                            <div className="col-lg-offset-3 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Nome</label>
                                                    <input type="text" className="form-control" id="name" name="name"  defaultValue={states.name} onChange={handleInputChange} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="function">Funzione</label>
                                                    <input type="text" className="form-control" id="function" name="function"  defaultValue={states.function} onChange={handleInputChange} />
                                                </div>
                                             
                                                <div style={{ display: states.type == 'm' ? 'block' : 'none' }} className="form-group col-md-6">
                                                    <label htmlFor="owner">Owner</label>
                                                    {states.submenu &&

                                                        <select className="form-control" id="owner" name="owner" defaultValue={states.owner} onChange={onSelect}>
                                                            <option value>seleziona ..</option>
                                                            {states.submenu.map((m, i) => {
                                                                return (
                                                                    <option key={`sub-${i}`} value={m.id}>{m.name}</option>
                                                                )
                                                            })}
                                                        </select>

                                                    }

                                                    {/* <Multiselect
                            options={states.submenu} // Options to display in the dropdown
                            displayValue="name" // Property name to display in the dropdown options
                            singleSelect={true}
                            onSelect={onSelect}
                            /> */}



                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="div_load">Div Load</label>
                                                    <input type="text" className="form-control" id="div_load" name="div_load"  defaultValue={states.div_load} onChange={handleInputChange} />
                                                </div>

                                                <div className="form-group">
                                                <label>Tipo</label><br/>
                <br/>
                                                    <select className="form-control" id="type" name="type" defaultValue={states.type} onChange={handleInputChange}>
                                                        <option value>seleziona ..</option>
                                                        <option value="a">A</option>
                                                        <option value="m">M</option>
                                                        <option value="file">File</option>
                                                        <option value="loaddiv">Load Div</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary" onClick={handleSave}>Salva</button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* /.card */}
                                </div>
                                {/* /.col*/}
                            </div>
                            {/* ./row */}
                        </section>
                        {/* /.content */}
                    </div>



                </div>



            </>

        )
    
}
export default EditSubMenu;