
import React, { Component } from 'react'


export default class NavOrizBar extends Component {

 
  render() {
    return (
      <>
<div className="navbar-collapsable" id="nav10">
<div className="overlay"></div>
<div className="close-div sr-only">
  <button className="btn close-menu" type="button"><span className="it-close"></span>close</button>
</div>
<div className="menu-wrapper">
  <ul className="navbar-nav">
    <li className="nav-item ">
      <a className="nav-link " href="/">
        <span>Home</span>
        
      </a>
    </li>
    <li className="nav-item ">
      <a className="nav-link " href="/#articolo-par-servizi">
        <span>Avvisi</span>
        
      </a>
    </li>

  </ul>
{/*    <ul className="navbar-nav navbar-secondary">
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 1</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 2</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="#"> Argomento in evidenza 3</a>
    </li>
    <li className="nav-item ">
      <a className="nav-link " href="#">
        <span className="font-weight-bold">Tutti gli argomenti...</span>
        
      </a>
    </li>
  </ul>
  */}
</div>
</div>

</>

)
}
}
