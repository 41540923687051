import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Strutture = () => {

    return (

        <>

            < iframe src="https://api.comune.rende.cs.it/Integrazione/" frameBorder={0} style={{  overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden', height: '1200px', width: '100%', position: 'relative', top: 0, left: 0, right: 0, bottom: 0 }
            } height="3050px" width="100%" />

        </>


    )
}

export default Strutture;