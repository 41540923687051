import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";




const Facilitazione = () => {

  return (
    <>
      <main>
        <div className="container my-4">

          <section id="intro">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-6 col-md-8">
                  <div className="titolo-sezione">
                    <h2>Progetto “Rete dei servizi di facilitazione digitale”, nell’ambito dell’Investimento 7 della Missione 1 del PNRR </h2>
                    <p>Con la facilitazione digitale PNRR, SPID, PagoPA, INPS e Agenzia delle Entrate non saranno più un mistero!</p>
                  </div>
                </div>
                <div className="offset-lg-1 col-lg-3 col-md-4">
                  <aside id="argomenti-sezione">
                    <div className="argomenti">

                      <h4>Argomenti</h4>
                      <div className="argomenti-sezione-elenco">
                        <a href="#" title="Vai all'argomento: SPID" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">SPID</a>
                        <a href="#" title="Vai all'argomento: PagoPA" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">PagoPA</a>
                        <a href="#" title="Vai all'argomento: INPS" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">INPS</a>
                        <a href="#" title="Vai all'argomento: Internet" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Internet</a>
                        <a href="#" title="Vai all'argomento: Servizi online" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Servizi online</a>
                        <a href="#" title="Vai all'argomento: Agenzie entrate" style={{ border: "solid 1px", padding: 10, margin: 3 }} className="badge badge-pill badge-argomenti">Agenzia delle Entrate</a>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="row" style={{ height: 20 }}>
            </div>
          </section>
          <section id="articolo-dettaglio-testo">
            <div className="row">
              <div className="col-12 col-lg-12">
                {/*start card*/}
                <div className="card-wrapper card-space">
                  <div className="card card-bg">
                    <div className="card-body">

                      <div className="articolo-paragrafi">
                        <div className="row">
                          <div className="">
                            <a id="articolo-par-competenze"> </a>
                            <h4> Servizio di Facilitazione Digitale PNRR Misura 1.7.2: al via le attività!</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">

                            <p>

                              Siamo lieti di annunciare l'imminente avvio delle attività del servizio di facilitazione digitale nell'ambito della Misura 1.7.2 del Piano Nazionale di Ripresa e Resilienza (PNRR).
                              <br /><br />
                              <strong>Obiettivo:</strong>
                              <br /><br />

                              L'obiettivo del servizio è quello di colmare il divario digitale e supportare i cittadini nell'utilizzo delle tecnologie digitali e dei servizi online, con particolare focus sui servizi digitali della Pubblica Amministrazione.
                              <br /><br />
                              <strong>Cosa offriamo:</strong>

                              <br />
                              Il servizio di facilitazione digitale offrirà ai cittadini una serie di servizi gratuiti, tra cui:
                              <br />
                              Formazione di base sull'utilizzo di internet, dei dispositivi digitali e dei servizi online
                              <br />Assistenza personalizzata per l'utilizzo dei servizi digitali della Pubblica Amministrazione, come SPID, PagoPA, INPS, Agenzia delle Entrate e molti altri
                              <br />Supporto per la risoluzione di problemi tecnici
                              <br />Informazioni e consigli su come navigare in sicurezza online
                              <br />
                              <br /><strong>A chi è rivolto:</strong>

                              <br />
                              <br />Il servizio è rivolto a tutti i cittadini, con particolare attenzione a:
                              <br />
                              <br />Persone anziane
                              <br />Persone con disabilità
                              <br />Cittadini stranieri
                              <br />Famiglie a basso reddito
                              <br />Persone che vivono in aree rurali

                            </p>
                          </div>
                          <div className="col-12 col-md-6">

                            <img width={'100%'} src="https://api.comune.rende.cs.it/VolantinoDigitale.jpg"></img>
                          </div>
                        </div>
                        <div className="row">
                          <br /><strong>Come accedere al servizio:</strong>

                        
                          <br />Il servizio sarà erogato attraverso una 3 punti di facilitazione digitale presenti su tutto il territorio dell'ambito Territoriale Sociale di Rende. (Rende, Castiglione Cosentino, Castrolibero, Marano Marchesato, Marano Principato, San Fili, San Pietro in Guarano, San Vincenzo la Costa).
                          <br />
                          <br />I 3 Punti di Facilitazione Digitale sono situati presso le sedi dei comuni di: 
                          
                          <br /> - Rende (Piazza San Carlo Borromeo).
                          <br /> - Castrolibero (Andreotta presso delegazione comunale in via Papa Bonifacio VIII).
                          <br /> - Rosa (Via Castello) .
                          <br />
                          <br /><strong>Innovazione per tutti:</strong>
                        
                          <br />Il servizio di facilitazione digitale è un'importante iniziativa per promuovere l'inclusione digitale e per avvicinare tutti i cittadini ai benefici offerti dalle tecnologie digitali.
                          <br />
                          <br />Non perdere l'occasione di essere parte della rivoluzione digitale!
                          <br />
                          <br /><strong>Per saperne di più:</strong>

                          <br />
                            <br />Contatta il tuo Comune
                          <br />Insieme, possiamo costruire un futuro digitale per tutti!
                          <br />
                          <br /><a href="https://assets.innovazione.gov.it/1671117323-faq_misura-1-7-2.pdf">https://assets.innovazione.gov.it/1671117323-faq_misura-1-7-2.pdf</a>
                        


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img width={'100%'} src="https://dtd-gov.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F000b05ef-1395-419d-91bd-5c616ff698bd%2Ffce731e7-a535-4b25-bdbd-8ddb6c1a8002%2Ffooter-capacity.png?table=block&id=5fdd2143-9f1d-49cd-bce8-842cc5711e4d&spaceId=000b05ef-1395-419d-91bd-5c616ff698bd&width=1540&userId=&cache=v2"></img>

          </section>

          <section>
          </section>

        </div>
      </main>

    </>
  )
}

export default Facilitazione;