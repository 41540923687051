import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


type DomandaFields = {
    nome: string,
    cognome: string,
    cf: string,
    nascita: string,
    comunenascita: string,
    provincianascita: string,
    indirizzoresidenza: string,
    civicoresidenza: string,
    capresidenza: string,
    capnascita: string,

    indirizzodomicilio: string,
    civicodomicilio: string,
    capdomicilio: string,
    comunedomicilio: string,
    provinciadomicilio: string,

    telefono: string,
    cellulare: string,
    pec: string,
    qualitadi: string,

    azienda: string,
    comunesede: string,
    indirizzosede: string,
    numerosede: string,
    cfazienda: string,
    piva: string,

    numerominori: number,

    dichiara1: boolean,
    dichiara2: boolean,
    dichiara3: boolean,
    dichiara4: boolean,

    dichiara5: boolean,
    dichiara6: boolean,
    dichiara7: boolean,
    dichiara8: boolean,

    dichiara9: boolean,
    dichiara10: boolean,
    dichiara11: boolean,
    dichiara12: boolean,
    dichiara33: boolean,
    dichiara13: boolean,
    dichiara14: boolean,
    dichiara15: boolean,
    dichiara16: boolean,
    dichiara17: boolean,
    dichiara18: boolean,
    dichiara19: boolean,
    dichiara20: boolean,
    dichiara21: boolean,
    dichiara23: boolean,
    dichiara22: boolean,

    dichiara66: boolean,
    allegati: []

}

const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({

    nome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cognome: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cf: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascita: Yup.string().required('Obbligatorio'),
    comunenascita: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    provincianascita: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    indirizzoresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 2 caratteri"),
    civicoresidenza: Yup.string().required('Obbligatorio').min(1, "inserire almeno 1 caratteri"),


    email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),


    dichiara1: Yup.boolean().isTrue(),

    dichiara3: Yup.boolean(),
    dichiara4: Yup.boolean(),
    dichiara5: Yup.boolean(),
    dichiara6: Yup.boolean(),
    dichiara7: Yup.boolean().isTrue(),
    dichiara8: Yup.boolean(),
    dichiara9: Yup.boolean().isTrue(),
    dichiara10: Yup.boolean().isTrue(),
    dichiara11: Yup.boolean().isTrue(),
    dichiara12: Yup.boolean().isTrue(),
    dichiara13: Yup.boolean().isTrue(),
    dichiara33: Yup.boolean().isTrue(),
    dichiara14: Yup.boolean().isTrue(),
    dichiara15: Yup.boolean().isTrue(),
    dichiara16: Yup.boolean().isTrue(),

    dichiara66: Yup.boolean().isTrue(),
    dichiara17: Yup.boolean().isTrue(),

    dichiara18: Yup.boolean(),
    dichiara19: Yup.boolean().isTrue(),
    dichiara20: Yup.boolean().isTrue(),
    dichiara21: Yup.boolean().isTrue(),
    dichiara22: Yup.boolean().isTrue(),
    dichiara23: Yup.boolean().isTrue(),
    dichiara2: Yup.boolean().isTrue(),

    allegati: Yup.array().of(

        Yup.object().shape({

            sostitutiva: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,

            cartaservizi: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            attocostitutivo: Yup
                .mixed()
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            documento: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            catalogo: Yup
                .mixed().required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    }),
            tariffario: Yup
                .mixed().required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    })

        })
    ),

})

const valoriIniziali: DomandaFields = {

    nome: "",
    cognome: "",
    cf: "",
    nascita: "",
    comunenascita: "",
    provincianascita: "",
    indirizzoresidenza: "",
    civicoresidenza: "",
    capresidenza: "",
    capnascita: "",

    indirizzodomicilio: "",
    civicodomicilio: "",
    capdomicilio: "",
    comunedomicilio: "",
    provinciadomicilio: "",

    telefono: "",
    cellulare: "",
    pec: "",
    qualitadi: "",

    azienda: "",
    comunesede: "",
    indirizzosede: "",
    numerosede: "",
    cfazienda: "",
    piva: "",

    numerominori: 0,

    dichiara1: false,
    dichiara2: false,
    dichiara3: false,
    dichiara4: false,

    dichiara5: false,
    dichiara6: false,
    dichiara7: false,
    dichiara8: false,

    dichiara9: false,
    dichiara10: false,
    dichiara11: false,
    dichiara12: false,

    dichiara13: false,
    dichiara14: false,
    dichiara15: false,
    dichiara16: false,
    dichiara33: false,
    dichiara17: false,
    dichiara18: false,
    dichiara19: false,
    dichiara20: false,
    dichiara21: false,
    dichiara22: false,
    dichiara23: false,

    dichiara66: false,

    allegati: []

    //*/
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Domandafornitori = () => {

    const [visual, setsetvisual] = useState(false)
    const [update, setupdate] = useState(false);
    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);
        //   nw.print();
        //   nw.close();
    }

    return (
        <>
            <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={false}
                validateOnChange={false}

                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "100011");
                    fd.append('nome', "Catalogo Fornitori");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });

                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });

                            window.location.href = "/esito?id=" + response.data.result;

                        }
                    }).catch((err) => {
                        console.log(err);

                        alert('Errore imprevisto. Provare a rinominare i file allegati senza utilizzare caratteri speciali');

                        document.getElementById('spinner').style.display = 'none';
                        document.getElementById('_conferma').style.display = 'inline';

                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">
                        <div className="form-group">
                            <div className="col-sm-12 col-sm-offset-1" >
                                <table>
                                    <tbody><tr>
                                        <td>
                                            <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                                <li className="active">
                                                    DOMANDA ISCRIZIONE AL CATALOGO DISTRETTUALE DI PRESTATORI ACCREDITATI PER L’EROGAZIONE DI
                                                    SERVIZI SOCIO-EDUCATIVI E DI SOSTEGNO EDUCATIVO EXTRASCOLASTICO IN FAVORE DI MINORI,
                                                    APPARTENENTI A NUCLEI FAMILIARI BENEFICIARI DI MISURE DI SOSTEGNO AL REDDITO E/O A RISCHIO DI
                                                    EMARGINAZIONE SOCIALE, PRESI IN CARICO DAL SERVIZIO SOCIALE DEI COMUNI DELL’AMBITO
                                                    TERRITORIALE N. 2 DEL DISTRETTO DI RENDE COMPRENDENTE I COMUNI DI CASTIGLIONE COSENTINO,
                                                    CASTROLIBERO, MARANO MARCHESATO, MARANO PRINCIPATO, RENDE, ROSE, SAN FILI, SAN PIETRO IN
                                                    GUARANO E SAN VINCENZO LA COSTA TRAMITE TITOLI DI ACQUISTO (VOUCHER).

                                                </li>
                                            </ol>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>

                        {<div id="div_domanda">
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Anagrafici </div></div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf' id='cf' onChange={event => formik.setFieldValue('cf', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf' /> </small>

                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita" name="nascita" onChange={evt => {
                                            formik.setFieldValue('nascita', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita1`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita' label='Provincia di nascita' />

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='comuneresidenza' label='Comune di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='provinciaresidenza' label='Provincia di residenza' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza' label='Via di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza' label='Civico di residenza' />
                                </div>

                            </div>

                            <div className="row">
                                Compilare solo se diverso da Residenza
                            </div>
                            <hr />
                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='comunedomicilio' label='Comune di domicilio' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='provinciadomicilio' label='Provincia di domicilio' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzodomicilio' label='Via di domicilio' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicodomicilio' label='Civico di domicilio' />
                                </div>

                            </div>

                            <br />

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti</div></div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='email'>e-mail</label>
                                        <input type="text" className="form-control" name='email' id='email' onChange={event => formik.setFieldValue('email', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='email' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='pec'>PEC </label>
                                        <input type="text" className="form-control" name='pec' id='pec' onChange={event => formik.setFieldValue('pec', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='pec' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='telefono' label='telefono' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>In qualità di Rappresentante di </div></div>

                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='azienda' label='Denominazione Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='comunesede' label='Comune sede' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzosede' label='Via  sede' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicosede' label='Civico  sede' />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='cfazienda' label='Codice fiscale Ente' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='piva' label='Partita Iva Ente' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>MANIFESTA</div></div>
                            <p><strong>IL PROPRIO INTERESSE ALL’INSERIMENTO NEL CATALOGO DISTRETTUALE PER L’EROGAZIONE
                                DI SERVIZI SOCIO-EDUCATIVI E DI SOSTEGNO EDUCATIVO EXTRASCOLASTICO IN FAVORE DI
                                MINORI, APPARTENENTI A NUCLEI FAMILIARI BENEFICIARI DI MISURE DI SOSTEGNO AL REDDITO
                                E/O A RISCHIO DI EMARGINAZIONE SOCIALE, PRESI IN CARICO DAL SERVIZIO SOCIALE DEI
                                COMUNI DELL’AMBITO TERRITORIALE N. 2 DEL DISTRETTO DI RENDE COMPRENDENTE I COMUNI
                                DI CASTIGLIONE COSENTINO, CASTROLIBERO, MARANO MARCHESATO, MARANO PRINCIPATO,
                                RENDE, ROSE, SAN FILI, SAN PIETRO IN GUARANO E SAN VINCENZO LA COSTA</strong> <br />
                                A tal fine, consapevole che la falsità in atti e le dichiarazioni mendaci sono punite ai sensi del codice
                                penale e delle leggi speciali in materia e che, laddove dovesse emergere la non veridicità di quanto
                                dichiarato, si avrà la decadenza dai benefici eventualmente ottenuti ai sensi dell’art. 75 del d.P.R. n.
                                445 del 28 dicembre 2000 e l’applicazione di ogni altra sanzione prevista dalla legge, nella predetta
                                qualità, ai sensi e per gli effetti di cui agli artt. 46 e 47 del d.P.R. n. 445 del 28 dicembre 2000</p>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARA</div></div>

                            <div className="row">

                                <div className="form-check">

                                    <input id="dichiara1" type="checkbox" onChange={event => formik.setFieldValue('dichiara1', event.target.checked)} />

                                    <label htmlFor="dichiara1" >di avere la sede e opera nei territori dell’Ambito Territoriale n.2 di Rende o in territori
                                        territorialmente confinanti nei territori limitrofi ai comuni facenti parte dell’Ambito Territoriale n.2 di
                                        Rende;</label>

                                    {formik.errors.dichiara1 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara3" type="checkbox" onChange={event => formik.setFieldValue('dichiara3', event.target.checked)} />

                                    <label htmlFor="dichiara3" >di essere iscritto nel Registro delle Organizzazioni di Volontariato e delle Associazioni di
                                        Promozione Sociale;</label>

                                    {formik.errors.dichiara3 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara4" type="checkbox" onChange={event => formik.setFieldValue('dichiara4', event.target.checked)} />

                                    <label htmlFor="dichiara4" > di essere iscritto all’Albo delle Cooperative Sociali;

                                    </label>

                                    {formik.errors.dichiara4 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara5" type="checkbox" onChange={event => formik.setFieldValue('dichiara5', event.target.checked)} />

                                    <label htmlFor="dichiara5" > di essere iscritto, se previsto ai rispettivi Albi e/o Registri a carattere nazionale, regionale o
                                        provinciale a seconda della natura giuridica dell’organizzazione e degli specifici dettami normativi;
                                    </label>

                                    {formik.errors.dichiara5 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara6" type="checkbox" onChange={event => formik.setFieldValue('dichiara6', event.target.checked)} />

                                    <label htmlFor="dichiara6" > di essere iscritto nel Registro delle Società e Associazioni Sportive;
                                    </label>

                                    {formik.errors.dichiara6 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara7" type="checkbox" onChange={event => formik.setFieldValue('dichiara7', event.target.checked)} />

                                    <label htmlFor="dichiara7" > di essere in regola con gli obblighi relativi al pagamento dei contributi previdenziali e assistenziali a
                                        favore dei propri lavoratori (se dovuti) ed accettare il vincolo di subordinare i pagamenti
                                        all’acquisizione Documento del Unico di Regolarità Contributiva;
                                    </label>

                                    {formik.errors.dichiara7 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara8" type="checkbox" onChange={event => formik.setFieldValue('dichiara8', event.target.checked)} />

                                    <label htmlFor="dichiara8" > di essere iscritto alla Camera di Commercio, Industria e Artigianato competente per territorio, se
                                        prevista per lo svolgimento dell’attività oggetto d’iscrizione;
                                    </label>

                                    {formik.errors.dichiara8 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara9" type="checkbox" onChange={event => formik.setFieldValue('dichiara9', event.target.checked)} />

                                    <label htmlFor="dichiara9" > di essere in possesso dei requisiti di ordine generale previsti dall’art.80 del D.Lgs. n. 50/2016 e
                                        ss.mm.ii.;
                                    </label>

                                    {formik.errors.dichiara9 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara10" type="checkbox" onChange={event => formik.setFieldValue('dichiara10', event.target.checked)} />

                                    <label htmlFor="dichiara10" > di essere in regola con le norme che disciplinano il diritto al lavoro dei disabili (legge n.68/99);
                                    </label>

                                    {formik.errors.dichiara10 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara11" type="checkbox" onChange={event => formik.setFieldValue('dichiara11', event.target.checked)} />

                                    <label htmlFor="dichiara11" > di assumere tutti gli obblighi di tracciabilità dei flussi finanziari di cui all’articolo 3 della Legge
                                        n.136 /2010 e ss.mm.ii.;
                                    </label>

                                    {formik.errors.dichiara11 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara12" type="checkbox" onChange={event => formik.setFieldValue('dichiara12', event.target.checked)} />

                                    <label htmlFor="dichiara12" > l’inesistenza di cause ostative di cui alla legge n.575/65 e s.m.i. (legge antimafia).
                                    </label>

                                    {formik.errors.dichiara12 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara13" type="checkbox" onChange={event => formik.setFieldValue('dichiara13', event.target.checked)} />

                                    <label htmlFor="dichiara13" > la compatibilità della natura giuridica e dello scopo sociale dei soggetti con le attività da realizzare;
                                    </label>

                                    {formik.errors.dichiara13 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara33" type="checkbox" onChange={event => formik.setFieldValue('dichiara33', event.target.checked)} />

                                    <label htmlFor="dichiara33" > essere in regola relativamente alla posizione assicurativa dei volontari ed alla posizione contributiva
                                        ed assistenziale dei dipendenti e collaboratori;
                                    </label>

                                    {formik.errors.dichiara33 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara14" type="checkbox" onChange={event => formik.setFieldValue('dichiara14', event.target.checked)} />

                                    <label htmlFor="dichiara14" > la propria disponibilità alla programmazione e alla realizzazione concertata degli interventi di che
                                        trattasi, con il coordinamento e la supervisione del Servizio Sociale Professionale dell’Ambito
                                        Territoriale Sociale di Rende;
                                    </label>

                                    {formik.errors.dichiara14 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara15" type="checkbox" onChange={event => formik.setFieldValue('dichiara15', event.target.checked)} />

                                    <label htmlFor="dichiara15" > di prendere visione e accettare tutte le condizioni contenute nell’Avviso Pubblico di Manifestazione
                                        di Interesse pubblicato dell’Ambito Territoriale n.2 di Rende;
                                    </label>

                                    {formik.errors.dichiara15 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara16" type="checkbox" onChange={event => formik.setFieldValue('dichiara16', event.target.checked)} />

                                    <label htmlFor="dichiara16" > di essere consapevole che nessun obbligo giuridico scaturisce con la sottoscrizione della
                                        convenzione da stipularsi tra l’Ambito Territoriale n.2 di Rende ed il prestatore dei servizi, fatto salvo
                                        il pagamento dei voucher;
                                    </label>

                                    {formik.errors.dichiara66 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara66" type="checkbox" onChange={event => formik.setFieldValue('dichiara66', event.target.checked)} />

                                    <label htmlFor="dichiara66" > di accettare che tutte le comunicazioni tra le parti avvengano a mezzo pec;
                                    </label>

                                    {formik.errors.dichiara16 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara17" type="checkbox" onChange={event => formik.setFieldValue('dichiara17', event.target.checked)} />

                                    <label htmlFor="dichiara17" > di non partecipare in violazione dei divieti di cui all’art.48, comma 7, del D.lgs 50/2016;
                                    </label>

                                    {formik.errors.dichiara17 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara18" type="checkbox" onChange={event => formik.setFieldValue('dichiara18', event.target.checked)} />

                                    <label htmlFor="dichiara18" > (per i richiedenti tenuti a tale adempimento ai sensi di legge) di essere iscritto nel registro delle
                                        imprese tenuto dalla CCIAA competente per lo svolgimento delle attività rispondenti a quelle oggetto
                                        della manifestazione d’interesse;
                                    </label>

                                    {formik.errors.dichiara18 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara19" type="checkbox" onChange={event => formik.setFieldValue('dichiara19', event.target.checked)} />

                                    <label htmlFor="dichiara19" > di essere informato, ai sensi del Regolamento (UE) 2016/679 del Parlamento europeo e del
                                        Consiglio del 27 aprile 2016, che i dati raccolti saranno trattati, anche con strumenti informatici,
                                        nell’ambito del procedimento per il quale la presente dichiarazione viene resa;
                                    </label>

                                    {formik.errors.dichiara19 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara20" type="checkbox" onChange={event => formik.setFieldValue('dichiara20', event.target.checked)} />

                                    <label htmlFor="dichiara20" > di essere a conoscenza che il Comune di Rende, si riserva il diritto di procedere d’ufficio a verifiche
                                        in ordine alla veridicità delle dichiarazioni rilasciate in sede di domanda e/o, comunque, nel corso della
                                        procedura, ai sensi e per gli effetti della normativa vigente;
                                    </label>

                                    {formik.errors.dichiara20 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara21" type="checkbox" onChange={event => formik.setFieldValue('dichiara21', event.target.checked)} />

                                    <label htmlFor="dichiara21" > di essere consapevole che l’accertamento della non veridicità del contenuto della presente
                                        dichiarazione, e delle ulteriori dichiarazioni rilasciate, comporterà, l’applicazione delle sanzioni e/o
                                        della revoca dell’assegnazione;
                                    </label>

                                    {formik.errors.dichiara21 && <small className="error">*Obbligatorio</small>}
                                </div> <div className="form-check">

                                    <input id="dichiara22" type="checkbox" onChange={event => formik.setFieldValue('dichiara22', event.target.checked)} />

                                    <label htmlFor="dichiara22" > di essere a conoscenza che qualora la non veridicità del contenuto della presente dichiarazione e
                                        delle ulteriori dichiarazioni rilasciate fosse accertata dopo la stipula della Convenzione, questa potrà
                                        essere risolta di diritto dall’Amministrazione ai sensi dell’art. 1456 c.c.;
                                    </label>

                                    {formik.errors.dichiara22 && <small className="error">*Obbligatorio</small>}
                                </div>
                                <div className="form-check">

                                    <input id="dichiara23" type="checkbox" onChange={event => formik.setFieldValue('dichiara23', event.target.checked)} />

                                    <label htmlFor="dichiara23" > di essere consapevole che l’eventuale iscrizione al Catalogo non comporta alcun diritto, né aspettativa
                                        o interesse qualificato, in quanto rappresenta una mera procedura di individuazione di soggetti da
                                        inserire nel suddetto Catalogo;
                                    </label>

                                    {formik.errors.dichiara23 && <small className="error">*Obbligatorio</small>}
                                </div>

                                <div className="form-check">

                                    <input id="dichiara2" type="checkbox" onChange={event => formik.setFieldValue('dichiara2', event.target.checked)} />

                                    <label htmlFor="dichiara2" > <strong>di poter ospitare minori, </strong>
                                    </label>

                                    {formik.errors.dichiara2 && <small className="error">*Obbligatorio</small>}
                                </div>

                            </div>

                            <div className="row" style={{ marginTop: 15 }}>
                                <div className="form-group">
                                    <Field component={InputField} id='numerominori' label='Numero' />
                                </div>
                            </div>
                            <>
                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Allega</div></div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="documento" className="active">
                                        copia fotostatica di un documento di riconoscimento in corso di validità del legale rappresentante
                                        del soggetto candidato;
                                    </label>
                                    <input type="file" name="documento" id="documento" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.documento`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.documento`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="cartaservizi" className="active">
                                        Carta dei servizi;
                                    </label>
                                    <input type="file" name="cartaservizi" id="cartaservizi" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.cartaservizi`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.cartaservizi`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label htmlFor="sostitutiva" className="active">Dichiarazione sostitutiva di certificazione <a target={'_blank'} href="/Dichiarazione sostitutiva di certificazione.doc"> (file esempio)</a></label>
                                    <input type="file" name="sostitutiva" id="sostitutiva" className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-6" style={{}}

                                        onChange={e => (formik.setFieldValue(`allegati.${0}.sostitutiva`, e.target.files[0]))}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.sostitutiva`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="attocostitutivo" className="active">
                                        Copia dell’Atto Costitutivo e dello Statuto registrato con indicazione dei nominativi di coloro che
                                        ricoprono le cariche sociali;
                                    </label>
                                    <input type="file" name="attocostitutivo" id="attocostitutivo" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.attocostitutivo`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.attocostitutivo`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="catalogo" className="active">
                                        Catalogo/Elenco dei servizi offerti.
                                    </label>
                                    <input type="file" name="catalogo" id="catalogo" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.catalogo`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.catalogo`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="tariffario" className="active">
                                        Tariffario mensile

                                    </label>
                                    <input type="file" name="tariffario" id="tariffario" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.tariffario`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.tariffario`} /></small>

                                </div>

                                <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                                    <span className="visually-hidden">In elaborazione...</span>
                                    <div className="progress-bar" role="progressbar" />
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                        <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                            onClick={() => {
                                                stampa_id('root');
                                                console.log(formik.errors);
                                            }}

                                        >Anteprima</button>
                                        {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                       */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                            // alert('invio');

                                            formik.validateForm().then(async res => {
                                                const { ob } = await res;

                                                let b = Object.keys(res).length;

                                                //       console.log('res', b, res);
                                                //     console.log(b);

                                                if (res.figli !== undefined) {

                                                    b = b - 1;

                                                    for (let i = 0; i < res.figli.length; i++) {
                                                        // @ts-ignore 
                                                        b += Object.keys(res.figli[i]).length;
                                                    }

                                                    console.log(b);
                                                }

                                                if (res.allegati !== undefined) {
                                                    b = b - 1;
                                                    // @ts-ignore 
                                                    b += Object.keys(res.allegati[0]).length;

                                                }
                                                console.log(b);

                                                if (b > 0) {
                                                    Toast.fire({
                                                        title: 'ci sono ' + b + ' Errori nella compilazione',
                                                        icon: 'error'
                                                    });
                                                } else {
                                                    document.getElementById('spinner').style.display = 'inline';
                                                    document.getElementById('_conferma').style.display = 'none';
                                                }

                                            });

                                            console.log(formik.errors);

                                            formik.submitForm();

                                        }}>Invia</button></div>
                                </div>
                            </>

                        </div>}
                    </div>)}
            </Formik>

        </>
    )
}



export default Domandafornitori;