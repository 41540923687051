import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


type DomandaFields = {
    nome1: string,
    cognome1: string,
    cf1: string,
    nascita1: string,
    comunenascita1: string,
    provincianascita1: string,
    indirizzoresidenza1: string,
    civicoresidenza1: string,

    nome2: string,
    cognome2: string,
    cf2: string,
    nascita2: string,
    comunenascita2: string,
    provincianascita2: string,
    indirizzoresidenza2: string,
    civicoresidenza2: string,

    qualitadi: string

    nomeminore: string,
    cognomeminore: string,
    cfminore: string,
    nascitaminore: string,
    comunenascitaminore: string,

    comunedistretto: string,
    isee: string,

    nomeistituto: string,
    nomescuola: string,
    comunescuola: string,

    iban: string,

    email: string,
    confermaemail: string,

    telefono: string,
    nucleo: [],

    dichiara1: boolean,
    dichiara2: boolean,
    dichiara3: boolean,
    dichiara4: boolean,
    allegati: []

}

const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({

    nome1: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cognome1: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cf1: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascita1: Yup.string().required('Obbligatorio'),
    comunenascita1: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    provincianascita1: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    indirizzoresidenza1: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    civicoresidenza1: Yup.string().required('Obbligatorio'),

    nome2: Yup.string(),
    cognome2: Yup.string(),
    cf2: Yup.string(),
    nascita2: Yup.string(),
    comunenascita2: Yup.string(),
    provincianascita2: Yup.string(),
    indirizzoresidenza2: Yup.string(),
    civicoresidenza2: Yup.string(),
    telefono: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    qualitadi: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    nomeminore: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cognomeminore: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    cfminore: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascitaminore: Yup.string().required('Obbligatorio'),
    comunenascitaminore: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    comunedistretto: Yup.string().required('Obbligatorio'),
    isee: Yup.string().required('Obbligatorio'),

    nomeistituto: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    nomescuola: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),
    comunescuola: Yup.string().required('Obbligatorio').min(2, "inserire almeno 2 caratteri"),

    iban: Yup.string().required('Obbligatorio').matches(
        /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/,
        "Iban non conforme"
    ),


    email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),
    confermaemail: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio').oneOf([Yup.ref('email'), null], "Email diversa!"),


    dichiara1: Yup.boolean().isTrue(),
    dichiara2: Yup.boolean().isTrue(),
    dichiara3: Yup.boolean().isTrue(),
    dichiara4: Yup.boolean().isTrue(),
    dichiara5: Yup.boolean().isTrue(),
    dichiara6: Yup.boolean().isTrue(),
    dichiara7: Yup.boolean().isTrue(),
    dichiara8: Yup.boolean().isTrue(),

    allegati: Yup.array().of(
        Yup.object().shape({
            iseee: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            ricevute: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            documenti: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            quietanze: Yup
                .mixed().test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    }),
            permesso: Yup
                .mixed().test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    }),
            extra: Yup
                .mixed().test("is-valid-size", "Dimensione Massima 2M",

                    (value) => {
                        if (!value) return true

                        return value && value.size <= MAX_FILE_SIZE
                    })


        })
    ),


    nucleo: Yup.array().of(
        Yup.object().shape({
            nome: Yup.string().required("Nome richiesto"),
            cognome: Yup.string().required("Cognome richiesto"),
            natoil: Yup.string().required('Obbligatorio'),
            cf: Yup.string().required('Obbligatorio').matches(
                /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
                "Codice fiscale non conforme"
            ),
            parentela: Yup.string().required("Cognome richiesto")
        })
    )
})

const valoriIniziali: DomandaFields = {

    nome1: "",
    cognome1: "",
    cf1: "",
    nascita1: "",
    comunenascita1: "",
    provincianascita1: "",
    indirizzoresidenza1: "",
    civicoresidenza1: "",

    nome2: "",
    cognome2: "",
    cf2: "",
    nascita2: "",
    comunenascita2: "",
    provincianascita2: "",
    indirizzoresidenza2: "",
    civicoresidenza2: "",

    qualitadi: "",

    nomeminore: "",
    cognomeminore: "",
    cfminore: "",
    nascitaminore: "",
    comunenascitaminore: "",

    comunedistretto: "",
    isee: "",

    nomeistituto: "",
    nomescuola: "",
    comunescuola: "",

    iban: "",

    email: "",
    confermaemail: "",

    telefono: "",
    nucleo: [],
    dichiara1: false,
    dichiara2: false,
    dichiara3: false,
    dichiara4: false,
    allegati: []
    //*/
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})


const Domanda = () => {


    const [visual, setsetvisual] = useState(false)
    const [update, setupdate] = useState(false);
    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);
        //   nw.print();
        //   nw.close();
    }
    const aggiungiComponente = (e: any, formik: any) => {
        //   console.log('figli', formik.values.figli);

        formik.values.nucleo = [];

        for (let i = 0; i < e; i++) {

            formik.values.nucleo.push({
                nome: '',
                cognome: '',
                natoil: '',
                cf: '',
                parentela: ''
            }
            );

        }
        //   console.log('figli', e, formik.values.figli);

        setsetvisual(true);

        setupdate(!update);

        if (!visual) {
            formik.values.allegati.push({
                documento: null,
                isee: null,
                verbale: null,
                dichiarazione: null,
                fileattivita: null,
                sostitutiva: null
            }
            );
        }

    }


    // const formik = useFormik({
    //    initialValues: valoriIniziali,
    //    validationSchema: yupSchema,
    //    validateOnBlur: false,
    //    validateOnChange:false,
    //    onSubmit:(values)=>{
    //     alert(JSON.stringify(values));
    //    }
    // })

    return (
        <>
            <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={false}
                validateOnChange={false}

                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    console.log(fileInput);
                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "100009");
                    fd.append('nome', "Domanda Voucher");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });


                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });


                            window.location.href = "/esito?id=" + response.data.result;


                        }
                    }).catch((err) => {
                        console.log(err);

                        alert('Errore imprevisto. Provare a rinominare i file allegati senza utilizzare caratteri speciali');

                        document.getElementById('spinner').style.display = 'none';
                        document.getElementById('_conferma').style.display = 'inline';

                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">
                        <div className="form-group">
                            <div className="col-sm-5 col-sm-offset-1" >
                                <table>
                                    <tbody><tr>
                                        <td>
                                            <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                                <li className="active">VOUCHER PER IL SERVIZIO DI REFEZIONE SCOLASTICA DELLLE SCUOLE DELL'INFANZIA PUBBLICHE— ANNO SCOLASTICO 2021- 2022</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>


                        {<div id="div_domanda">
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Anagrafici  (genitori/tutori) del minore </div></div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome1' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome1' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf1'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf1' id='cf1' onChange={event => formik.setFieldValue('cf1', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf1' /> </small>

                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita1">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita1" name="nascita1" onChange={evt => {
                                            formik.setFieldValue('nascita1', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita1`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita1' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita1' label='provincianascita' />

                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza1' label='Via di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza1' label='Civico di residenza' />
                                </div>
                            </div>

                            <hr />
                            <br />

                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome2' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome2' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf2'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf2' id='cf2' onChange={event => formik.setFieldValue('cf2', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf2' /> </small>

                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita2">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita2" name="nascita2" onChange={evt => {
                                            formik.setFieldValue('nascita2', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita2`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita2' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita2' label='provincianascita' />

                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza2' label='Via di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza2' label='Civico di residenza' />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div>
                                        <h5>In qualità di : </h5></div>
                                    <Field component={InputField} id='qualitadi' label='(genitore/tutore)' />
                                </div>
                            </div>

                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti</div></div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='email'>e-mail</label>
                                        <input type="text" className="form-control" name='email' id='email' onChange={event => formik.setFieldValue('email', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='email' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='confermaemail'>conferma e-mail</label>
                                        <input type="text" className="form-control" name='confermaemail' id='confermaemail' onChange={event => formik.setFieldValue('confermaemail', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='confermaemail' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='telefono' label='telefono' />
                                </div>

                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati del Minore</div></div>


                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <Field component={InputField} id='nomeminore' label='Nome minore' />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <Field component={InputField} id='cognomeminore' label='Cognome minore' />
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cfminore'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cfminore' id='cfminore' onChange={event => formik.setFieldValue('cfminore', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cfminore' /> </small>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascitaminore">Data di nascita minore (gg/mm/aaaa)</label>
                                        <input type="date" id="nascitaminore" name="nascitaminore" onChange={evt => {
                                            formik.setFieldValue('nascitaminore', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascitaminore`} />
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <Field component={InputField} id='comunenascitaminore' label='Comune nascita minore' />
                                    </div>
                                </div>

                            </div>






                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>CHIEDONO</div></div>
                            <p>Il rimborso delle spese sostenute  per il servizio di refezione del figlio regolarmente iscritto nell’anno scolastico 2021/2022, al servizio di refezione scolastica delle scuole dell’infanzia pubbliche  (Avviso pubblico, approvato con Determinazione dirigenziale n. 95 del 15/03/2023).</p>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>A TAL FINE DICHIARANO</div></div>
                            <p>ai sensi degli artt. 46 e 47 del DPR 28 dicembre 2000, n. 445, sotto la propria responsabilità e nella consapevolezza delle conseguenze penali in caso di dichiarazione mendace, formazione o uso di atti falsi e della decadenza dai benefici ottenuti sulla base di dichiarazioni non veritiere:</p>

                            <div className="row">
                                <div className="col-sm" style={{ maxWidth: 315 }}>
                                    <div className="form-check">

                                        <input id="dichiara1" type="checkbox" onChange={event => formik.setFieldValue('dichiara1', event.target.checked)} />

                                        <label htmlFor="dichiara1" >di essere residenti nel Comune di</label>

                                        {formik.errors.dichiara1 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>

                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <Field component={InputField} id='comunedistretto' label='comune del distretto' />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm" style={{ maxWidth: 800 }}>
                                    <div className="form-check">

                                        <input id="dichiara2" type="checkbox" onChange={event => formik.setFieldValue('dichiara2', event.target.checked)} />

                                        <label htmlFor="dichiara2" >di aver iscritto al servizio di refezione scolastica del/la proprio/a figlio/a, per l'a.s. 2021/2022, presso</label>

                                        {formik.errors.dichiara2 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>


                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <Field component={InputField} id='nomeistituto' label='nomeistituto' />
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-sm" style={{ maxWidth: 150 }}>
                                    <p>denominata</p>
                                </div>
                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <Field component={InputField} id='nomescuola' label='denominazione scuola' />
                                </div>
                                <div className="col-sm" style={{ maxWidth: 200 }}>
                                    <p>sita nel comune di </p>
                                </div>
                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <Field component={InputField} id='comunescuola' label='comune scuola' />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <div className="form-check">

                                        <input id="dichiara3" type="checkbox" onChange={event => formik.setFieldValue('dichiara3', event.target.checked)} />

                                        <label htmlFor="dichiara3" > di avere un ISEE di € </label>

                                        {formik.errors.dichiara3 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>


                                <div className="col-sm" style={{ maxWidth: 300 }}>
                                    <Field component={InputField} id='isee' label='isee' />
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-sm" >
                                    <div className="form-check">

                                        <input id="dichiara4" type="checkbox" onChange={event => formik.setFieldValue('dichiara4', event.target.checked)} />

                                        <label htmlFor="dichiara4" > che non beneficino di altri rimborsi o sovvenzioni economiche o azioni di supporto dirette e/o
                                            indirette erogate allo stesso titolo, di importo tale da superare la spesa complessivamente sostenuta; </label>

                                        {formik.errors.dichiara4 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm" >
                                    <div className="form-check">

                                        <input id="dichiara5" type="checkbox" onChange={event => formik.setFieldValue('dichiara5', event.target.checked)} />

                                        <label htmlFor="dichiara5" > che il proprio nucleo familiare è così composto da  <select id="numfigli" style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => { aggiungiComponente(e.target.value, formik); }}>
                                            <option value="0"> Seleziona Numero Componenti</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                        </select> componenti (compresi il richiedente): </label>

                                        {formik.errors.dichiara5 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>
                            </div>


                            <FieldArray
                                name="figli"
                                render={arrayHelpers => {
                                    const nucleo = formik.values.nucleo;
                                    function handleFormChange(index: React.Key, event: React.ChangeEvent<HTMLInputElement>): void {

                                        formik.values.nucleo[index+''][event.target.name.split('.')[2]] = event.target.value;
                                        //   console.log('value ', event.target.value, event.target.name.split('.')[2])

                                    }

                                    return (
                                        <div>
                                            {nucleo && nucleo.length > 0
                                                ? nucleo.map((nucleo: any, index: React.Key) => (
                                                    <div key={index}>

                                                        <div style={{ border: 'solid', padding: 30 }} key={index}>
                                                            <div className="row"><label>Componente {parseInt('' + index) + 1}</label>

                                                                <div className="col-sm">
                                                                    <div className="form-group">

                                                                        <label htmlFor={`nucleo.${index}.nome`}>Nome</label>
                                                                        <input type="text" className="form-control" name={`nucleo.${index}.nome`} id={`nucleo.${index}.nome`} onChange={event => handleFormChange(index, event)} />
                                                                        <small className="error">    <ErrorMessage name={`nucleo.${index}.nome`} /> </small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`nucleo.${index}.cognome`}>Cognome</label>
                                                                        <input type="text" className="form-control" name={`nucleo.${index}.cognome`} id={`nucleo.${index}.cognome`} onChange={event => handleFormChange(index, event)} />
                                                                        <small className="error">    <ErrorMessage name={`nucleo.${index}.cognome`} /> </small>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label className="active" htmlFor={`nucleo.${index}.natoil`}>Data di nascita (gg/mm/aaaa)</label>
                                                                        <input type="date" id={`nucleo.${index}.natoil`} name={`nucleo.${index}.natoil`} onChange={event => handleFormChange(index, event)} />
                                                                        <small className="error">  <ErrorMessage name={`nucleo.${index}.natoil`} /> </small>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row">

                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`nucleo.${index}.cf`}>Codice Fiscale</label>
                                                                        <input type="text" className="form-control" name={`nucleo.${index}.cf`} id={`nucleo.${index}.cf`} onChange={event => handleFormChange(index, event)} />
                                                                        <small className="error">  <ErrorMessage name={`nucleo.${index}.cf`} /> </small>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor={`nucleo.${index}.parentela`}>Grado di Parentela</label>
                                                                        <input type="text" className="form-control" name={`nucleo.${index}.parentela`} id={`nucleo.${index}.parentela`} onChange={event => handleFormChange(index, event)} />
                                                                        <small className="error">    <ErrorMessage name={`nucleo.${index}.parentela`} /> </small>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                ))
                                                : null}

                                        </div>
                                    );
                                }}
                            />










                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARANO, inoltre,
                            </div></div>
                            <p>Di voler ricevere la liquidazione del contributo economico eventualmente concesso, a mezzo di accredito sul seguente conto corrente bancario o postale intestato o cointestato al richiedente
                            </p>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='iban'>Iban</label>
                                        <input type="text" className="form-control" name='iban' id='iban' onChange={event => formik.setFieldValue('iban', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='iban' /> </small>

                                    </div>
                                </div>
                            </div>


                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>DICHIARANO, altresì,
                            </div></div>


                            <div className="row">
                                <div className="col-sm" >
                                    <div className="form-check">

                                        <input id="dichiara6" type="checkbox" onChange={event => formik.setFieldValue('dichiara6', event.target.checked)} />

                                        <label htmlFor="dichiara6" > di accettare che tutte le comunicazioni tra le parti avvengano a mezzo email e che l'indirizzo indicato tra i recapiti sarà anche utilizzato per le comunicazioni relative alla presente procedura e per la gestione del servizio</label>

                                        {formik.errors.dichiara6 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm" >
                                    <div className="form-check">

                                        <input id="dichiara7" type="checkbox" onChange={event => formik.setFieldValue('dichiara6', event.target.checked)} />

                                        <label htmlFor="dichiara7" > di essere a conoscenza del fatto che il Comune di Rende potrà effettuare controlli per la verifica delle autocertificazioni presentate e, nei casi di dichiarazioni false rilasciate al fine di ottenere indebitamente il beneficio di che trattasi, il richiedente decadrà immediatamente dal beneficio concessogli e potrà incorrere nelle sanzioni del codice penale e dalle leggi specifiche in materia;
                                        </label>

                                        {formik.errors.dichiara7 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm" >
                                    <div className="form-check">

                                        <input id="dichiara8" type="checkbox" onChange={event => formik.setFieldValue('dichiara8', event.target.checked)} />

                                        <label htmlFor="dichiara8" > di essere informato, ai sensi del Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016, che i dati raccolti saranno trattati, anche con strumenti informatici, nell’ambito del procedimento per il quale la presente dichiarazione viene resa;

                                        </label>

                                        {formik.errors.dichiara8 && <small className="error">*Obbligatorio</small>}
                                    </div>
                                </div>
                            </div>


                            <br />


                            {visual && <>
                                <br />

                                <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Allega</div></div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="documenti" className="active">Documenti di riconoscimento di entrambi i genitori in corso di validità. </label>
                                    <input type="file" name="documenti" id="documenti" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.documenti`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.documenti`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="permesso" className="active">
                                        Fotocopia permesso di soggiorno (solo per gli extracomunitari)
                                    </label>
                                    <input type="file" name="permesso" id="permesso" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.permesso`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.permesso`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="ricevute" className="active">
                                        ricevute/fatture rilasciate dall’istituto per i mesi di effettiva frequenza ove sia specificato l'importo spettante per il solo costo del servizio di refezione scolastica nel periodo giugno settembre 2021/giugno 2022;
                                    </label>
                                    <input type="file" name="ricevute" id="ricevute" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.ricevute`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.ricevute`} /></small>

                                </div>


                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="quietanze" className="active">
                                        quietanza di pagamento laddove risulti, per ciascuna fattura/ricevuta:
                                        <br /> 1. dichiarazione sostitutiva, ai sensi del D.P.R. n. 445/2000, in caso di pagamento in contanti (a firma del Legale Rappresentante della Scuola);
                                        <br /> 2. estratto conto ovvero singolo movimento contabile ove risulti il pagamento effettuato alla scuola (conto corrente del genitore).

                                    </label>
                                    <input type="file" name="quietanze" id="quietanze" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.quietanze`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.quietanze`} /></small>

                                </div>


                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="iseee" className="active">
                                        DSU e ISEE in corso di validità rivolto alle prestazioni agevolate per minori;

                                    </label>
                                    <input type="file" name="iseee" id="iseee" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.iseee`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.iseee`} /></small>

                                </div>

                                <div className="form-upload" style={{ padding: 20 }}>
                                    <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="extra" className="active">
                                        Ulteriore documentazione attestante particolari situazioni rispetto ai parametri sopraindicati.

                                    </label>
                                    <input type="file" name="extra" id="extra" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                        onChange={e => formik.setFieldValue(`allegati.${0}.extra`, e.target.files[0])}
                                    />
                                    <small className="error"><ErrorMessage name={`allegati.${0}.extra`} /></small>

                                </div>


                                <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                                    <span className="visually-hidden">In elaborazione...</span>
                                    <div className="progress-bar" role="progressbar" />
                                </div>
                                <hr />
                                <div className="form-group">
                                    <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                        <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                            onClick={() => {
                                                stampa_id('root');
                                                console.log(formik.errors);
                                            }}

                                        >Anteprima</button>
                                        {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                   */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                            // alert('invio');

                                            formik.validateForm().then(async res => {
                                                const { ob } = await res;

                                                let b = Object.keys(res).length;


                                                //       console.log('res', b, res);

                                                //     console.log(b);

                                                if (res.figli !== undefined) {

                                                    b = b - 1;

                                                    for (let i = 0; i < res.figli.length; i++) {
                                                        // @ts-ignore 
                                                        b += Object.keys(res.figli[i]).length;
                                                    }

                                                    console.log(b);
                                                }

                                                if (res.allegati !== undefined) {
                                                    b = b - 1;
                                                    // @ts-ignore 
                                                    b += Object.keys(res.allegati[0]).length;

                                                }

                                                console.log(b);


                                                if (b > 0) {
                                                    Toast.fire({
                                                        title: 'ci sono ' + b + ' Errori nella compilazione',
                                                        icon: 'error'
                                                    });
                                                } else {
                                                    document.getElementById('spinner').style.display = 'inline';
                                                    document.getElementById('_conferma').style.display = 'none';

                                                }


                                            });

                                            console.log(formik.errors);

                                            formik.submitForm();

                                        }}>Invia</button></div>
                                </div>
                            </>
                            }
                        </div>}
                    </div>)}
            </Formik>


        </>
    )
}

export default Domanda;