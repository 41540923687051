import React, { Component } from 'react'

export default class Accordion extends Component {
    state={
        collapse:true
    }



    render() {
        return (
            <div className="panel card card-primary card-outline">
                <div className="card-header with-border">
                    <h4 className="card-title">
                        <a data-toggle="collapse" data-parent="#accordion" aria-expanded="false" href="#" onClick={(evt)=>{
                            evt.preventDefault();
                            this.setState({collapse:!this.state.collapse})
                        }}>
                            {this.props.name}
                        </a>
                    </h4>
                </div>
                <div className={`card-body ${this.state.collapse ? 'collapse':''}`}>
                    
                    {this.props.children && this.props.children}
                </div>
            </div>
        )
    }
}
