import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import styles from "./shared-component/dnd/tree-dnd/styles";
import InputField from "./shared-component/Input/InputField";


type DomandaFields = {
    nome: string,
    cognome: string,
    cf: string,
    nascita: string,
    comunenascita: string,
    provincianascita: string,
    provinciaresidenza: string,
    comuneresidenza: string,
    indirizzoresidenza: string,
    civicoresidenza: string,
    nomeoperatore: string,
    telefono: string,
    comunesede: string,
    provinciasede: string,

    cfsede: string,
    pivasede: string,
    registro: string,
    pec: string,
    confermapec: string,

    email: string,
    confermaemail: string,
    cellulare: string,


    comuneregistro: string,
    numeroregistro: string,
    annoregistro: string,

    dichiara1: boolean,
    dichiara2: boolean,
    dichiara3: boolean,
    dichiara4: boolean,
    dichiara5: boolean,
    dichiara6: boolean,
    dichiara7: boolean,
    dichiara8: boolean,
    dichiara9: boolean,
    dichiara10: boolean,
    dichiara11: boolean,
    dichiara12: boolean,
    dichiara13: boolean,
    dichiara14: boolean,
    dichiara15: boolean,
    dichiara16: boolean,
    dichiara17: boolean,
    dichiara18: boolean,
    dichiara19: boolean,
    dichiara20: boolean,
    dichiara21: boolean,
    dichiara22: boolean,
    privacy: boolean,
    allegati: []
}

const MAX_FILE_SIZE = 2000400; //100KB

const validFileExtensions = { image: ['jpg', 'pdf', 'tif', 'png', 'jpeg'] };

const yupSchema = Yup.object().shape({

    nome: Yup.string().required().min(2, "inserire almeno 2 caratteri"),
    cognome: Yup.string().required().min(2, "inserire almeno 2 caratteri"),
    cf: Yup.string().required('Obbligatorio').matches(
        /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/,
        "Codice fiscale non conforme"
    ),
    nascita: Yup.string().required('Obbligatorio'),
    comunenascita: Yup.string().required(),
    provincianascita: Yup.string().required(),
    provinciaresidenza: Yup.string().required(),
    comuneresidenza: Yup.string().required(),
    indirizzoresidenza: Yup.string().required(),
    civicoresidenza: Yup.string().required(),


    comunesede: Yup.string().required(),
    provinciasede: Yup.string().required(),

    cfsede: Yup.string().required().matches(/\b\d{11}\b/, { message: 'lunghezza 11 caratteri' }),
    pivasede: Yup.string().required().matches(/\b\d{11}\b/, { message: 'lunghezza 11 caratteri' }),
    nomeoperatore: Yup.string().required(),

    pec: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),
    confermapec: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio').oneOf([Yup.ref('pec'), null], "Email diversa!"),


    email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio'),
    confermaemail: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required('Obbligatorio').oneOf([Yup.ref('email'), null], "Email diversa!"),
    cellulare: Yup.string().required(),
    registro: Yup.string().required(),

    comuneregistro: Yup.string().required(),
    numeroregistro: Yup.string().required(),
    annoregistro: Yup.string().required(),
    telefono: Yup.string(),
    indirizzosede: Yup.string().required(),
    civicosede: Yup.string().required(),

    dichiara1: Yup.boolean().isTrue(),
    dichiara2: Yup.boolean().isTrue(),
    dichiara3: Yup.boolean().isTrue(),
    dichiara4: Yup.boolean().isTrue(),
    dichiara5: Yup.boolean().isTrue(),
    dichiara6: Yup.boolean().isTrue(),
    dichiara7: Yup.boolean().isTrue(),
    dichiara8: Yup.boolean().isTrue(),
    dichiara9: Yup.boolean().isTrue(),
    dichiara10: Yup.boolean().isTrue(),
    dichiara11: Yup.boolean().isTrue(),
    dichiara12: Yup.boolean().isTrue(),
    dichiara13: Yup.boolean().isTrue(),
    dichiara14: Yup.boolean().isTrue(),
    dichiara15: Yup.boolean().isTrue(),
    dichiara16: Yup.boolean().isTrue(),
    dichiara17: Yup.boolean().isTrue(),
    dichiara18: Yup.boolean().isTrue(),
    dichiara19: Yup.boolean().isTrue(),
    dichiara20: Yup.boolean().isTrue(),
    dichiara21: Yup.boolean().isTrue(),
    dichiara22: Yup.boolean().isTrue(),

    privacy: Yup.boolean().isTrue(),

    allegati: Yup.array().of(
        Yup.object().shape({
            cartaservizi: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Max allowed size is 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            statuto: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Max allowed size is 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            dichiarazione: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Dimensione Massima 2M",
                    value => value && value.size <= MAX_FILE_SIZE)
            ,
            documento: Yup
                .mixed()
                .required("Required")
                .test("is-valid-size", "Max allowed size is 2M",
                    value => value && value.size <= MAX_FILE_SIZE)


        })
    ),

})



const valoriIniziali: DomandaFields = {
    nome: "",
    cognome: "",
    cf: "",

    comunenascita: "",
    provincianascita: "",
    comuneresidenza: "",
    indirizzoresidenza: "",
    civicoresidenza: "",
    nomeoperatore: "",
    email: "",
    telefono: "",
    registro: "",
    confermaemail: "",
    cellulare: "",
    dichiara1: false,
    dichiara2: false,
    dichiara3: false,
    dichiara4: false,
    dichiara5: false,
    dichiara6: false,
    dichiara7: false,
    dichiara8: false,
    privacy: false,
    allegati: [],
    nascita: "",

    comunesede: "",
    provinciasede: "",

    cfsede: "",
    pivasede: "",
    provinciaresidenza: "",
    pec: "",
    confermapec: "",


    comuneregistro: "",
    numeroregistro: "",
    annoregistro: "",
    dichiara9: false,
    dichiara10: false,
    dichiara11: false,
    dichiara12: false,
    dichiara13: false,
    dichiara14: false,
    dichiara15: false,
    dichiara16: false,
    dichiara17: false,
    dichiara18: false,
    dichiara19: false,
    dichiara20: false,
    dichiara21: false,
    dichiara22: false
}
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})



const Domanda = () => {

    const [visual, setsetvisual] = useState(false)
    const [update, setupdate] = useState(false);
    const stampa_id = (id: any) => {
        var nw = window.open();
        var html = document.body.innerHTML + '<style> .body{ width: 200px} </style>'

        nw.document.write(document.body.innerHTML);
        //   nw.print();
        //   nw.close();
    }

    // const formik = useFormik({
    //    initialValues: valoriIniziali,
    //    validationSchema: yupSchema,
    //    validateOnBlur: false,
    //    validateOnChange:false,
    //    onSubmit:(values)=>{
    //     alert(JSON.stringify(values));
    //    }
    // })



    return (
        <>
            <Formik initialValues={valoriIniziali}
                validationSchema={yupSchema}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={(values) => {

                    const fileInput = document.querySelectorAll('input[type="file"]');

                    console.log(fileInput);
                    const data = JSON.stringify({ ...values })
                    const fd = new FormData();
                    // append directly as part of the postData in plain text
                    fd.append('istanzajson', data);
                    fd.append('identificativo', "100005");
                    fd.append('nome', "Domanda Elenco operatori economici");

                    fileInput.forEach((docItem) => {
                        fd.append(docItem.id, (document.getElementById(docItem.id) as HTMLInputElement).files[0]);
                    });


                    axios.post(API_URL_LARAVEL + '/istanza', fd, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                    ).then((response) => {

                        console.log(response);

                        if (response.data.success) {
                            Toast.fire({
                                title: response.data.text,
                                icon: 'success'
                            });

                            window.location.href = "/esito?id=" + response.data.result;

                        }
                    }).catch((err) => {
                        console.log(err);
                    });

                    //    alert(JSON.stringify(values));
                }}>
                {(formik: FormikProps<any>) => (
                    <div className="form">
                        <div className="form-group">
                            <div className="col-sm-5 col-sm-offset-1" >
                                <table>
                                    <tbody><tr>
                                        <td>
                                            <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                                <li className="active">
                                                    Domanda per l’iscrizione all’Elenco aperto di Operatori Economici per l’erogazione di
                                                    prestazioni da parte di Operatori Socio Sanitari (OSS) ed Operatori Socio Assistenziali (OSA)
                                                    in favore dei beneficiari del bando FNA 2015

                                                </li>
                                            </ol>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        </div>


                        <div id="div_domanda">
                            <br />
                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Anagrafici </div></div>
                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='nome' label='Nome' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='cognome' label='Cognome' />
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="nascita">Data di nascita (gg/mm/aaaa)</label>
                                        <input type="date" id="nascita" name="nascita" onChange={evt => {
                                            formik.setFieldValue('nascita', evt.target.value)
                                        }} />
                                        <ErrorMessage name={`nascita`} />

                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor='cf'>Codice Fiscale</label>
                                        <input type="text" className="form-control" name='cf' id='cf' onChange={event => formik.setFieldValue('cf', event.target.value)} />
                                        <small className="error">  <ErrorMessage name='cf' /> </small>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='comunenascita' label='Comune di nascita' />
                                </div>
                                <div className="col-sm">

                                    <Field component={InputField} id='provincianascita' label='provincianascita' />

                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comuneresidenza' label='Comune di Residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzoresidenza' label='Indirizzo di Residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicoresidenza' label='Civico di residenza' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='provinciaresidenza' label='Provincia di residenza' />
                                </div>
                            </div>

                            <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>In qualità di Rappresentante Legale di</div></div>
                            <div className="row">

                                <div className="col-sm">
                                    <Field component={InputField} id='nomeoperatore' label='Denominazione' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='cfsede' label='Codice Fiscale operatore' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='pivasede' label='P.Iva operatore' />
                                </div>



                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <Field component={InputField} id='comunesede' label='Comune sede' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='provinciasede' label='Provincia sede' />
                                </div>

                                <div className="col-sm">
                                    <Field component={InputField} id='indirizzosede' label='Indirizzo sede' />
                                </div>
                                <div className="col-sm">
                                    <Field component={InputField} id='civicosede' label='N° Civico sede' />
                                </div>

                            </div>

                        </div>

                        <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Recapiti Operatore</div></div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor='email'>e-mail</label>
                                    <input type="text" className="form-control" name='email' id='email' onChange={event => formik.setFieldValue('email', event.target.value)} />
                                    <small className="error">  <ErrorMessage name='email' /> </small>
                                </div>
                            </div>
                            <div className="col-sm">

                                <div className="form-group">
                                    <label htmlFor='confermaemail'>conferma e-mail</label>
                                    <input type="text" className="form-control" name='confermaemail' id='confermaemail' onChange={event => formik.setFieldValue('confermaemail', event.target.value)} />
                                    <small className="error">  <ErrorMessage name='confermaemail' /> </small>
                                </div>


                            </div>
                            <div className="col-sm">
                                <Field component={InputField} id='telefono' label='telefono' />
                            </div>
                            <div className="col-sm">
                                <Field component={InputField} id='cellulare' label='Cellulare' />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor='pec'> Pec</label>
                                    <input type="text" className="form-control" name='pec' id='pec' onChange={event => formik.setFieldValue('pec', event.target.value)} />
                                    <small className="error">  <ErrorMessage name='pec' /> </small>
                                </div>
                            </div>
                            <div className="col-sm">

                                <div className="form-group">
                                    <label htmlFor='confermapec'>conferma Pec</label>
                                    <input type="text" className="form-control" name='confermapec' id='confermapec' onChange={event => formik.setFieldValue('confermapec', event.target.value)} />
                                    <small className="error">  <ErrorMessage name='confermapec' /> </small>
                                </div>


                            </div>

                        </div>

                        <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Iscritto nel seguente Registro / Albo per attività rientrante in</div></div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <div className="select-wrapper">
                                        <label htmlFor="registrosel">Registro</label>
                                        <select id="registrosel" onChange={event => {
                                            if (event.target.value == 'altro') {
                                                formik.setFieldValue('registro', '')
                                                document.getElementById('registrval').style.display = 'inline';
                                            } else {
                                                formik.setFieldValue('registro', event.target.value)
                                                document.getElementById('registrval').style.display = 'none';
                                            }
                                        }}>
                                            <option selected value="">Scegli un'opzione</option>
                                            <option value="C.C.I.A.A.">C.C.I.A.A.</option>
                                            <option value="Albo Regionale">Albo Regionale</option>
                                            <option value="RUNTS">RUNTS</option>
                                            <option value="altro">Altro</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{ display: 'none' }} id="registrval">
                                    <Field component={InputField} id='registro' label='Indicare Registro' />
                                </div>
                            </div>
                            <div className="col-sm">
                                <Field component={InputField} id='comuneregistro' label='Comune' />
                            </div>
                            <div className="col-sm">
                                <Field component={InputField} id='numeroregistro' label='Numero' />
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label className="active" htmlFor='annoregistro'>Data iscrizione</label>
                                    <input type="date" id='annoregistro' name='annoregistro' onChange={event => formik.setFieldValue('annoregistro', event.target.value)} />
                                    <small className="error">  <ErrorMessage name='annoregistro' /> </small>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>CHIEDE</div></div>
                        <p>l’iscrizione all’elenco aperto di operatori per l’erogazione di prestazioni da parte di Operatori Socio Sanitari (OSS) ed Operatori Socio Assistenziali (OSA) in favore dei beneficiari del bando FNA 2015 ed anche per ulteriori servizi della stessa natura o ritenuti analoghi, erogati dall’Ambito Territoriale n.2 del distretto di Rende.

                        </p>

                        <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>A tal fine DICHIARA </div></div>
                        <p>ai sensi degli articoli 46 e 47 del D.P.R. 28.12.2000, n. 445, consapevole delle sanzioni penali previste dall'articolo 76 e delle conseguenze di cui all’art.75 del medesimo D.P.R., per le ipotesi di falsità in atti e dichiarazioni mendaci ivi indicate, quanto segue:</p>
                        <div>
                            <div className="form-check">

                                <input id="dichiara1" type="checkbox" onChange={event => formik.setFieldValue('dichiara1', event.target.checked)} />

                                <label htmlFor="dichiara1" >la compatibilità della natura giuridica e dello scopo sociale, così come previsto dallo statuto, con le prestazioni da erogare;</label>
                                {formik.errors.dichiara1 && <small className="error">*Obbligatorio</small>}
                            </div>

                            <div className="form-check">
                                <input id="dichiara2" type="checkbox" onChange={event => formik.setFieldValue('dichiara2', event.target.checked)} />
                                <label htmlFor="dichiara2" >di impegnarsi a garantire la presenza di operatori socio-sanitari (O.S.S.) e Operatori socio-assistenziali (O.S.A.) in numero adeguato alle ore di servizio da erogare, come da piano assistenziale individualizzato (PAI);
                                </label>
                                {formik.errors.dichiara2 && <small className="error">*Obbligatorio</small>}
                            </div>

                            <div className="form-check">
                                <input id="dichiara3" type="checkbox" onChange={event => formik.setFieldValue('dichiara3', event.target.checked)} />
                                <label htmlFor="dichiara3" >di avere comprovata esperienza di almeno 12 mesi riguardante le attività previste dal presente avviso;</label>
                                {formik.errors.dichiara3 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara4" type="checkbox" onChange={event => formik.setFieldValue('dichiara4', event.target.checked)} />
                                <label htmlFor="dichiara4" >di impegnarsi a stipulare idonea polizza assicurativa per i rischi di responsabilità civile verso terzi, compatibile con la tipologia del servizio erogato;
                                </label>
                                {formik.errors.dichiara4 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara5" type="checkbox" onChange={event => formik.setFieldValue('dichiara5', event.target.checked)} />
                                <label htmlFor="dichiara5" >di essere in regola con gli obblighi relativi al pagamento dei contributi previdenziali e assistenziali a favore dei propri lavoratori (se dovuti) ed accettare il vincolo di subordinare i pagamenti all’acquisizione Documento Unico di Regolarità Contributiva;
                                </label>
                                {formik.errors.dichiara5 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara6" type="checkbox" onChange={event => formik.setFieldValue('dichiara6', event.target.checked)} />
                                <label htmlFor="dichiara6" >di essere in possesso dei requisiti di ordine generale previsti dall’art.80 del D.lgs. n. 50/2016 e ss.mm.ii.;
                                </label>
                                {formik.errors.dichiara6 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara7" type="checkbox" onChange={event => formik.setFieldValue('dichiara7', event.target.checked)} />
                                <label htmlFor="dichiara7" >che non sussistono le condizioni di cui all'art. 53, comma 16-ter, del D.lgs. del 2001, n. 165 o di cui all'art. 35 del decreto-legge 24 giugno 2014, n. 90 convertito con modificazioni dalla Legge 11 agosto 2014, n. 114;
                                </label>
                                {formik.errors.dichiara7 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara8" type="checkbox" onChange={event => formik.setFieldValue('dichiara8', event.target.checked)} />
                                <label htmlFor="dichiara8" >di non essere incorsi, ai sensi della normativa vigente, in ulteriori divieti a contrattare con la pubblica amministrazione;</label>
                                {formik.errors.dichiara8 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara9" type="checkbox" onChange={event => formik.setFieldValue('dichiara9', event.target.checked)} />
                                <label htmlFor="dichiara9" >di non versare in alcuna causa legalmente ostativa o di incompatibilità anche di fatto o di conflitto di interessi, che rende illegittimo o inopportuno, rispetto all’interesse pubblico, l’affidamento dei servizi e delle prestazioni;</label>
                                {formik.errors.dichiara9 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara10" type="checkbox" onChange={event => formik.setFieldValue('dichiara10', event.target.checked)} />
                                <label htmlFor="dichiara10" >di assumere tutti gli obblighi di tracciabilità dei flussi finanziari di cui all’articolo 3 della Legge n.136 /2010 e ss.mm.ii.;</label>
                                {formik.errors.dichiara10 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara11" type="checkbox" onChange={event => formik.setFieldValue('dichiara11', event.target.checked)} />
                                <label htmlFor="dichiara11" >l’inesistenza di cause ostative di cui alla legge n.575/65 (antimafia);</label>
                                {formik.errors.dichiara11 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara12" type="checkbox" onChange={event => formik.setFieldValue('dichiara12', event.target.checked)} />
                                <label htmlFor="dichiara12" >di non partecipare in violazione dei divieti di cui all’art.48, comma 7, del D.lgs. 50/2016;</label>
                                {formik.errors.dichiara12 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara13" type="checkbox" onChange={event => formik.setFieldValue('dichiara13', event.target.checked)} />
                                <label htmlFor="dichiara13" >di non essere incorso, nel biennio precedente, nella cancellazione dall’Albo dei fornitori di beni, lavori o servizi tenuto da una pubblica amministrazione;</label>
                                {formik.errors.dichiara13 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara14" type="checkbox" onChange={event => formik.setFieldValue('dichiara14', event.target.checked)} />
                                <label htmlFor="dichiara14" >l’osservanza nei riguardi dei propri dipendenti di tutte le disposizioni previste nei vigenti contratti collettivi, salariali, previdenziali ed assicurative, disciplinanti il rapporto di lavoro e di tutte le norme vigenti in materia di sicurezza sul lavoro, in particolare quelle previste dal D.lgs. n. 81/2008 e s.m.i.;</label>
                                {formik.errors.dichiara14 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara15" type="checkbox" onChange={event => formik.setFieldValue('dichiara15', event.target.checked)} />
                                <label htmlFor="dichiara15" >di essere in regola con le norme che disciplinano il diritto al lavoro dei disabili (legge n.68/99);</label>
                                {formik.errors.dichiara15 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara16" type="checkbox" onChange={event => formik.setFieldValue('dichiara16', event.target.checked)} />
                                <label htmlFor="dichiara16" >l’assenza, nei ventiquattro mesi precedenti la domanda, di provvedimenti di sospensione o cancellazione dall’Albo regionale / registro;</label>
                                {formik.errors.dichiara16 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara17" type="checkbox" onChange={event => formik.setFieldValue('dichiara17', event.target.checked)} />
                                <label htmlFor="dichiara17" >di non avere mai prodotto documentazioni o autocertificazioni false al fine di ottenere l’aggiudicazione dei servizi e delle prestazioni;</label>
                                {formik.errors.dichiara17 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara18" type="checkbox" onChange={event => formik.setFieldValue('dichiara18', event.target.checked)} />
                                <label htmlFor="dichiara18" >di aver preso visione e accettare tutte le condizioni contenute nella presente Manifestazione di interesse;</label>
                                {formik.errors.dichiara18 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara19" type="checkbox" onChange={event => formik.setFieldValue('dichiara19', event.target.checked)} />
                                <label htmlFor="dichiara19" >di essere consapevole che l’eventuale iscrizione all’elenco non comporta nessun obbligo giuridico da parte del Comune di Rende in qualità di Ente capofila dell’ambito territoriale n. 2, fatto salvo il pagamento del “buono servizio” solo ed esclusivamente per le prestazioni effettivamente rese sulla base del PAI e della scelta effettuata dai beneficiari;</label>
                                {formik.errors.dichiara19 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara20" type="checkbox" onChange={event => formik.setFieldValue('dichiara20', event.target.checked)} />
                                <label htmlFor="dichiara20" >di essere a conoscenza che il Comune di Rende si riserva il diritto di procedere d’ufficio a verifiche in ordine alla veridicità delle dichiarazioni rilasciate in sede di domanda e/o, comunque, nel corso della procedura, ai sensi e per gli effetti della normativa vigente e che l’accertamento della non veridicità del contenuto della presente dichiarazione e delle ulteriori dichiarazioni rilasciate, comporterà, l’applicazione delle sanzioni e/o della revoca dell’assegnazione;</label>
                                {formik.errors.dichiara20 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara21" type="checkbox" onChange={event => formik.setFieldValue('dichiara21', event.target.checked)} />
                                <label htmlFor="dichiara21" >di essere disponibile alla predisposizione di tutta la documentazione probatoria attestante l’effettiva erogazione del servizio assegnato sulla base di quanto previsto dall’Ente;</label>
                                {formik.errors.dichiara21 && <small className="error">*Obbligatorio</small>}
                            </div>
                            <div className="form-check">
                                <input id="dichiara22" type="checkbox" onChange={event => formik.setFieldValue('dichiara22', event.target.checked)} />
                                <label htmlFor="dichiara22" >di essere informato, ai sensi del Regolamento UE 2016/679 e del D.lgs. 196/2003, che i dati raccolti saranno trattati, anche con strumenti informatici, nell’ambito del procedimento per il quale la presente dichiarazione viene resa;</label>
                                {formik.errors.dichiara22 && <small className="error">*Obbligatorio</small>}
                            </div>
                        </div>
                        <br />
                        <div className="form-group"><div className="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Allega</div></div>

                        <div className="form-upload" style={{ padding: 20 }}>
                            <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="documento" className="active">Fotocopia non autenticata del documento d'identità del dichiarante in corso di validità;</label>
                            <input type="file" name="documento" id="documento" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                onChange={e => formik.setFieldValue(`allegati.${0}.documento`, e.target.files[0])}
                            />
                            <small className="error"><ErrorMessage name={`allegati.${0}.documento`} /></small>

                        </div>

                        <div className="form-upload" style={{ padding: 20 }}>
                            <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="statuto" className="active">Statuto.</label>
                            <input type="file" name="statuto" id="statuto" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                onChange={e => formik.setFieldValue(`allegati.${0}.statuto`, e.target.files[0])}
                            />
                            <small className="error"><ErrorMessage name={`allegati.${0}.statuto`} /></small>

                        </div>

                        <div className="form-upload" style={{ padding: 20 }}>
                            <label style={{ textOverflow: 'unset', whiteSpace: 'unset', lineHeight: 'inherit' }} htmlFor="cartaservizi" className="active">Carta dei Servizi sociali secondo quanto previsto dall'art. 13 della legge 8 novembre 2000, n. 328, e dall’art. 21 della legge regionale 26 novembre 2003, n. 23;
                            </label>
                            <input type="file" name="cartaservizi" id="cartaservizi" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-1"
                                onChange={e => formik.setFieldValue(`allegati.${0}.cartaservizi`, e.target.files[0])}
                            />
                            <small className="error"><ErrorMessage name={`allegati.${0}.cartaservizi`} /></small>
                        </div>

                        <div className="form-upload" style={{ padding: 20 }}>
                            <label htmlFor="dichiarazione" className="active">Dichiarazione sostitutiva di certificazione <a target={'_blank'} href="/Dichiarazionesostitutiva.doc"> (file esempio)</a></label>
                            <input type="file" name="dichiarazione" id="dichiarazione" className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-6" style={{}}

                                onChange={e => (formik.setFieldValue(`allegati.${0}.dichiarazione`, e.target.files[0]))}
                            />
                            <small className="error"><ErrorMessage name={`allegati.${0}.dichiarazione`} /></small>

                        </div>

                        <div className="form-check">
                            <input id="privacy" type="checkbox" onChange={event => formik.setFieldValue('privacy', event.target.checked)} />
                            <label htmlFor="privacy" > Acconsento al trattamento dei miei dati e dichiaro di aver preso visione della <a target={"_blank"} href="https://www.comune.rende.cs.it/privacy-policy/">Privacy Policy </a>
                            </label>
                            {formik.errors.privacy && <small className="error">*Obbligatorio</small>}
                        </div>

                        <div style={{ display: 'none' }} id='spinner' className="progress progress-indeterminate">
                            <span className="visually-hidden">In elaborazione...</span>
                            <div className="progress-bar" role="progressbar" />
                        </div>
                        <hr />
                        <div className="form-group">
                            <div className="btn-group col-sm-5 col-sm-offset-6 ">
                                <button name="_anteprima" id="_anteprima" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150, display: 'none' }}

                                    onClick={() => {
                                        stampa_id('root');
                                        console.log(formik.errors);
                                    }}

                                >Anteprima</button>
                                {/**   <button name="_stampadomanda" id="_stampadomanda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Stampa domanda</button>
                                   */}  <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }} onClick={() => {

                                    // alert('invio');


                                    formik.validateForm().then(async res => {
                                        const { ob } = await res;

                                        let b = Object.keys(res).length;

                                        if (res.allegati !== undefined) {
                                            b = b - 1;
                                            // @ts-ignore 
                                            b += Object.keys(res.allegati[0]).length;

                                        }
                                        console.log(b);
                                        if (b > 0) {
                                            Toast.fire({
                                                title: 'ci sono ' + b + ' Errori nella compilazione',
                                                icon: 'error'
                                            });
                                        } else {
                                            document.getElementById('spinner').style.display = 'inline';
                                            document.getElementById('_conferma').style.display = 'none';
                                        }

                                    });

                                    console.log(formik.errors);
                                    formik.submitForm();

                                }}>Invia</button></div>
                        </div>


                    </div>)}
            </Formik>
        </>
    )
}

export default Domanda;