import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class HeaderContent extends Component {
    render() {
        return (
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 style={{ textShadow: '1px 1px 2px gray' }}>
                                {this.props.title}
                            </h1>
                            <small> {this.props.subtitle} </small>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {
                                    this.props.links.map((l, i) => {
                                        if (!l.active) {
                                            return (
                                                <li key={i} className={`breadcrumb-item ${l.active ? 'active' : ''}`}><Link to={l.url}>
                                                    {l.icon !== undefined && <i className={`fa ${l.icon}`} />}
                                                    {l.name}
                                                </Link></li>
                                            )
                                        } else {
                                            return (
                                                <li key={i} className={`breadcrumb-item ${l.active ? 'active' : ''}`}>
                                                    {l.icon !== undefined && <i className={`fa ${l.icon}`} />}
                                                    {l.name}
                                                </li>
                                            )
                                        }

                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

        )
    }
}
