import React, { Component } from 'react'

export default class loading extends Component {
    render() {
        return (
            <div className="loading">
               ...loading
            </div>
        )
    }
}
