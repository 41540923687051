import axios from "axios";
import { useState, useEffect } from 'react';
import { Field, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import AuthUser from '../components/AuthUser';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";
import { useNavigate } from 'react-router-dom';
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})



const Domande = () => {

    const aprimodal = () => {

        document.getElementById('nuovoavviso').style.display = "inline";
    }

    const http = AuthUser().http;
    const user = AuthUser().user;
    const navigate = useNavigate();
    const [avvisi, setavvsis] = useState(null);
    const [updatepage, setupdatepage] = useState(true);

    const nuovoAvviso = () => {

        const fd = new FormData();
        // @ts-ignore
        fd.append('titolo', document.getElementById('titolo').value);
        // @ts-ignore
        fd.append('tag', document.getElementById('tag').value);
        // @ts-ignore
        fd.append('dataapertura', document.getElementById('dataapertura').value + ' ' + document.getElementById('oraapertura').value);
        // @ts-ignore
        fd.append('datafine', document.getElementById('datafine').value + ' ' + document.getElementById('orafine').value);
        // @ts-ignore
        fd.append('abstract', document.getElementById('abstract').value);
        // @ts-ignore
        fd.append('identificativo', document.getElementById('identificativo').value);

        http.post('/table/avvisis/create', fd).then(response => {

            document.getElementById('nuovoavviso').style.display = "none";
            setupdatepage(!updatepage);
            Toast.fire({
                title: response.data.text,
                icon: 'success'
            });
        })

    }


    useEffect(() => {
        console.log(user.ambito);
        // @ts-ignore 
        http.post('/table/avvisis/get', user.ambito == 'si' ? { where: 'ambito-1' } : {}).then(response => {
            // @ts-ignore 
            setavvsis(response.data.result);
            //   console.log(avvisi);
        });

    }, [updatepage]);

    return (
        <>
            <h3>Avvisi</h3>

            <button type="button" className="btn btn-primary"
                onClick={() => {
                    aprimodal();
                }}
            >Nuovo Avviso</button>

            <div id="nuovoavviso" style={{ 'display': 'none' }}>
                <div className="modal-dialog" style={{ maxWidth: '100%' }} role="document">
                    <div className="modal-content">

                        <div className="modal-body" >
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="titolo" >Inserisci Titolo</label>
                                        <input type="text" className="form-control" id="titolo" required data-focus-mouse="false" aria-invalid="false" />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="identificativo" >Inserisci identificativo</label>
                                        <input type="text" className="form-control" id="identificativo" required data-focus-mouse="false" aria-invalid="false" />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="tag" >Inserisci Tag</label>
                                        <input type="text" className="form-control" name="tag" id="tag" required data-focus-mouse="false" aria-invalid="false" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="dataapertura">Data di Apertura</label>
                                        <input type="date" id="dataapertura" name="dataapertura" />

                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="oraapertura">Orario</label>
                                        <input className="form-control" id="oraapertura" type="time" />
                                    </div>

                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="datafine">Data di Chiusura</label>
                                        <input type="date" id="datafine" name="datafine" />

                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="active" htmlFor="orafine">Orario</label>
                                        <input className="form-control" id="orafine" type="time" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="abstract" >Abstract</label>
                                        <textarea id="abstract" rows={3} data-focus-mouse="false" defaultValue={""} />
                                    </div>
                                </div>
                            </div>
                            <button id="buttfaq" type="button" className="btn btn-primary"
                                onClick={() => {
                                    nuovoAvviso();
                                }}
                            >Salva</button>
                        </div>

                        <h5 style={{ padding: 15 }} id="resultfaq"></h5>

                    </div>
                </div>
            </div>


            <div className="card-body">

                <table id="tablemenu" className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Id </th>
                            <th>Identificativo</th>
                            <th>Titolo</th>
                            <th>Data Apertura</th>
                            <th>Data Fine</th>
                            <th>Azioni</th>

                        </tr>
                    </thead>
                    <tbody>
                        {avvisi && avvisi.map((m: any, i: any) => {
                            return (
                                <tr key={m.id}>
                                    <td>{m.id}</td>
                                    <td>{m.identificativo}</td>
                                    <td>{m.titolo}</td>
                                    <td> {m.dataapertura}</td>
                                    <td>{m.datafine}</td>
                                    <td>
                                        <a className="elementor-button-link elementor-button elementor-size-sm" onClick={() => navigate('/istanze', { state: { identificativo: m.identificativo } })} role="button">
                                            Istanze</a>
                                        {/* <Link to={'/istanze/'} className="col-lg-offset-5 btn btn-success">Istanze</Link> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Domande;