
import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import { useLocation, useParams } from 'react-router-dom';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import IconSelector from '../../../components/shared-component/IconSelector';
import Swal from 'sweetalert2';


const links = [
    { url: '/app/home', name: 'Home', active: false },
    { url: '/app/menu/nuovomenu', name: 'Menù', active: false },
    { url: '/app/menu/editmenu', name: 'Nuovo Menù', active: true },
];

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const EditMenu = (props) => {
    const http = AuthUser().http;

    const params = useParams();
    console.log(params);
    var newinsert=1;
    if(params.id==0)newinsert=0;

   const  [states,setstates]= useState({
    id: params.id,
    name: null,
    class_icon: 'fa-500px',
    posizione: null,
    has_submenus: false
});

   
    useEffect(()=> {

               if (newinsert) {
            http.get(`menu/${states.id}/edit`).then((response) => {

               setstates({ ...response.data })

             })
        } 
    },[]);



   const handleSave = () => {



        if (!newinsert) {
            http.post('menu', { ...states }).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            })
        } else {
            http.put(`menu/${states.id}`, states).then((response) => {
                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                }
            })
        }
    }

        return (
            <>
                <div>
                    <HeaderContent title={states.insertNew ? 'Nuovo Menù' : 'Edit Menù'} subtitle="navigazione" links={links} />

                    {/* Main content */}
                    <section className="content">
                        <div className="row justify-content-md-center m-0">
                            <div className="col-md-12">
                                {/* general form elements */}
                                <div className="card card-primary">
                                
                                    {/* /.box-header */}
                                    {/* form start */}

                                    <div className="card-body">
                                        <div className="col-lg-offset-3 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Nome</label>
                                                <input type="text" className="form-control" id="name" name="name"  defaultValue={states.name} onChange={(evt) => states.name= evt.target.value } />
                                            </div>
                                            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

                                            <div className="form-group">
                                                <IconSelector OnChange={(evt) => states.class_icon = evt } defaultValue={states.class_icon}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="posizione">Posizione</label>
                                                <input type="number" className="form-control" id="posizione" name="posizione"  defaultValue={states.posizione} onChange={(evt) => states.posizione = evt.target.value} />
                                            </div>
                                            <div className="form-group">

                                            <label style={{ paddingLeft: '20px' }}  htmlFor="hassubmenu">Ha Submenu</label>
                                                <input style={{ marginTop: '15px' }} type="checkbox" id="hassubmenu" name="hassubmenu"  defaultChecked={states.has_submenus}
                                                    onChange={(evt) => states.has_submenus= evt.target.checked } />
                                                
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary" onClick={handleSave}>Salva</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* /.box */}
                            </div>
                            {/* /.col*/}
                        </div>
                        {/* ./row */}
                    </section>
                    {/* /.content */}
                </div>



            </>

        )
    
}

export default EditMenu;