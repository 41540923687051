import React, { Component }  from 'react';
export default function Avvisoalbo() {
    return (
      <>

      <main>
        <div className="container my-4">

          <section id="intro">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-6 col-md-8">
                  <div className="titolo-sezione">
                    <h2>Avviso per</h2>
                    <p>DOMANDA ISCRIZIONE IN UN ALBO DISTRETTUALE DI PRESTATORI ACCREDITATI PER L’EROGAZIONE DI SERVIZI SOCIO-EDUCATIVI E DI SOSTEGNO EDUCATIVO EXTRASCOLASTICO IN FAVORE DEI DESTINATARI MISURA SIA/REI/RdC TRAMITE TITOLI DI ACQUISTO (VOUCHER).
</p>

                  </div>
                </div>
                <div className="offset-lg-1 col-lg-3 col-md-4">
                  <aside id="argomenti-sezione">
                    <div className="argomenti">

         </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="row" style={{ height: 20 }}>
            </div>
          </section>
          <section id="articolo-dettaglio-testo">
            <div className="row">
              <div className="col-12 col-lg-12">
                {/*start card*/}
                <div className="card-wrapper card-space">
                  <div className="card card-bg">
                    <div className="card-body">


                      <div className="articolo-paragrafi">
                        <div className="row">
                          <div className="">
                            <a id="avviso-par-competenze"> </a>
                            <h4>AVVISO</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <p>
                            IL PROPRIO INTERESSE ALL’INSERIMENTO NELL’ALBO DI PRESTATORI ACCREDITATI PER L’EROGAZIONE DI SERVIZI SOCIO-EDUCATIVI E DI SOSTEGNO EDUCATIVO EXTRASCOLASTICO IN FAVORE DI MINORI, APPARTENENTI A NUCLEI FAMILIARI PRESI IN CARICO DAL SERVIZIO SOCIALE DEI COMUNI DELL’AMBITO TERRITORIALE N. 2 DEL DISTRETTO SOCIO ASSISTENZIALE  DI RENDE COMPRENDENTE I COMUNI DI CASTIGLIONE COSENTINO, CASTROLIBERO, MARANO MARCHESATO, MARANO PRINCIPATO, RENDE, ROSE, SAN FILI, SAN PIETRO IN GUARANO E SAN VINCENZO LA COSTA, TRAMITE TITOLI DI ACQUISTO (VOUCHER).
                                 </p>

                                 <div className="it-card-footer">
                         <span className="card-signature"></span>
                         <a className="btn btn-outline-primary btn-sm" href="/interesseaccrediti">Vai alla Domanda</a>
                       </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
 </div>
      </main>

      <a href="#" aria-hidden="true" data-attribute="back-to-top" className="back-to-top">
        <svg className="icon icon-light">
          <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-up"></use>
        </svg>
      </a>


    </>


    )
}