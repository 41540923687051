import React, { Component } from "react";
import { useEffect, Suspense, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ContentExample from './components/layout-component/ContentExample';
import Loading from './components/shared-component/Loading'
import Login from './components/login';
import Dashboard from './components/dashboard';
import Ipaddress from './views/app-views/sicurezza.ipaddresses/elencoipaddress';
import Menu from './views/app-views/sviluppo.menu/nuovomenu';
import Submenu from './views/app-views/sviluppo.submenu/elencosubmenu';
import Permessi from './views/app-views/sviluppo.permessi/permessi';
import Role from './views/app-views/sviluppo.ruoli/elencoruoli';
import Sede from './views/app-views/sviluppo.sede/nuovosede';
import MyAccount from './views/app-views/profilo/myaccount';
import Domanda from './components/domanda';
import Fornitori from './components/fornitori';
import RimborsoSpese from './components/rimborsospese';
import Accrediti from './components/interesseaccrediti';
import Domandafornitori from './components/domandafornitori';
import ElencoO from './components/elencooperatori';
import ElencoOperatori from './components/elencooperatorieconomici';
import DomandaRimborso from './components/domandaRimborso';
import Avvisoalbo from './components/avvisoalbo';
import NuovoMenu from './views/app-views/sviluppo.menu/editmenu';
import EditSubMenu from './views/app-views/sviluppo.submenu/editsubmenu';
import EditRole from './views/app-views/sviluppo.ruoli/editruolo';
import Faq from "./components/faq";
import Error from "./components/error";
import Domande from "./components/domande";
import Istanze from "./components/istanze";
import Istanza from "./components/istanza";
import Esito from "./components/esito";
import Fna from "./components/fna";
import Cron from "./components/cron";
import Strutture from "./components/strutture";
import Agendaurbana from "./components/domandaAgendaUrbana";
import TrasportoScolastico from "./components/domandatrasportoscolastico";
import RefezioneScolastica from "./components/domandarefezionescolastica";
import Voucher from "./components/domandaVoucherScuola";
import GestoriEstivi from "./components/gestoriestivi";
import DomandaEstivi23 from "./components/domandaEstivi2023";
import SostegnoFamiglie2021 from "./components/domandaSostegnoFamiglie";
import Longlist from "./components/Longlist";
import Facilitazione from "./components/Facilitazione";

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<ContentExample />} />
          <Route path="login" element={<Login />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="ipaddress" element={<Ipaddress />} />
          <Route path="menu" element={<Menu />} />
          <Route path="permessi" element={<Permessi />} />
          <Route path="domande" element={<Domande />} />
          <Route path="submenu" element={<Submenu />} />
          <Route path="role" element={<Role />} />
          <Route path="sede" element={<Sede />} />
          <Route path="esito" element={<Esito />} />
          <Route path="error" element={<Error />} />
          <Route path="faq" element={<Faq />} />
          <Route path="fna" element={<Fna />} />
          <Route path="cron" element={<Cron />} />
          <Route path="strutture" element={<Strutture />} />
          <Route path="voucher" element={<Voucher />} />
          <Route path="istanza" element={<Istanza />} />
          <Route path="istanze" element={<Istanze />} />
          <Route path="/profile/mioaccount" element={<MyAccount />} />
          <Route path="domanda" element={<Domanda />} />
          <Route path="domandafornitori" element={<Domandafornitori />} />
          <Route path="domandarimborsonew" element={<DomandaRimborso />} />
          <Route path="domandacentriestivi23" element={<DomandaEstivi23 />} />
          <Route path="SostegnoFamiglie2021" element={<SostegnoFamiglie2021 />} />
          
          <Route path="domandarimborso" element={<Navigate to="/domandarimborsonew" />} />
          <Route path="elencooperatorieconomici" element={<ElencoOperatori />} />
          <Route path="elencooperatori" element={<ElencoO />} />
          <Route path="fornitori" element={<Fornitori />} />
          <Route path="agendaurbana" element={<Agendaurbana />} />
          <Route path="trasporto" element={<TrasportoScolastico />} />
          <Route path="longlist" element={<Longlist />} />
          <Route path="facilitazione" element={<Facilitazione />} />
          <Route path="refezione" element={<RefezioneScolastica />} />
          <Route path="interesseaccrediti" element={<Accrediti />} />
          <Route path="albofornitori" element={<Avvisoalbo />} />
          <Route path="gestoriestivi" element={<GestoriEstivi />} />
          <Route path="rimborsospese" element={<RimborsoSpese />} />
          <Route path="/app/menu/create/:id" element={<NuovoMenu id={0} />} />
          <Route path="/app/submenu/create/:menuId/:id" element={<EditSubMenu id={0} menuId={0} />} />
          <Route path="/app/role/create/:id" element={<EditRole id={0} />} />

        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
