import React, { Component } from 'react'
import NavOrizBar from './NavOrizBar'


export default class Header extends Component {

 
  render() {
    return (
      <>
    <header className="it-header-wrapper it-header-sticky" style={{flexShrink: 0}}>


  <div className="it-nav-wrapper">
    <div className="it-header-center-wrapper theme-light">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="it-header-center-content-wrapper">
              <div className="it-brand-wrapper">
                <a href="/">
           
           <img className='logocomune' src='/logo_comune.png'></img>
                    <div className="it-brand-text">
                    <div className="it-brand-title">Servizi Sociali</div>
                    <div className="it-brand-tagline d-none d-md-block">Un aiuto certo e veloce</div>
                  </div>
                </a>
              </div>
              <div className="it-right-zone">
     
              <div className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" data-toggle="dropdown" aria-expanded="false">
                  <span className="sr-only">Selezione lingua: lingua selezionata</span>
                  <span>ITA</span>
                  <svg className="icon d-none d-lg-block">
                    <use href="/bootstrap-italia/dist/svg/sprite.svg#it-expand"></use>
                  </svg>
                </a>
                <div className="dropdown-menu">
                  <div className="row">
                    <div className="col-12">
                      <div className="link-list-wrapper">
                        <ul className="link-list">
                          <li><a className="list-item" href="#"><span>ITA <span className="sr-only">selezionata</span></span></a></li>
                          <li><a className="list-item" href="#"><span>ENG</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a className="btn btn-primary btn-icon btn-full" href="/login" title="Accedi all'area personale" aria-label="Accedi all'area personale">
                <span className="rounded-icon">
                  <svg className="icon icon-primary">
                    <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-user"></use>
                  </svg>
                </span>
                <span className="d-none d-lg-block">Accedi all'area personale</span>
              </a>
                     
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="it-header-navbar-wrapper theme-light-desk">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg has-megamenu">
              <button
                aria-controls="nav10"
                aria-expanded="false"
                aria-label="Toggle navigation"
                className="custom-navbar-toggler"
                data-target="#nav10"
                type="button"
              >
                <svg className="icon">
                  <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-burger"></use>
                </svg>
              </button>
           <NavOrizBar/>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
      </>

    )
  }
}
