import React, { Component } from 'react';
export default function Avvisoalbo() {
  return (
    <>

      <main>
        <div className="container my-4">

          <section id="intro">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-6 col-md-8">
                  <div className="titolo-sezione">
                    <h2>Avviso </h2>
                    <p>
                      Manifestazione di Interesse per l’iscrizione all’Elenco aperto di Operatori Economici per l’erogazione di prestazioni da parte di Operatori Socio Sanitari (OSS) ed Operatori Socio Assistenziali (OSA) in favore dei beneficiari del bando FNA 2015
                    </p>

                  </div>
                </div>
                <div className="offset-lg-1 col-lg-3 col-md-4">
                  <aside id="argomenti-sezione">
                    <div className="argomenti">

                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="row" style={{ height: 20 }}>
            </div>
          </section>
          <section id="articolo-dettaglio-testo" >
            <div className="row">
              <div className="col-12 col-lg-12">
                {/*start card*/}
                <div className="card-wrapper card-space">
                  <div className="card card-bg" style={{ background: '#f2f7fc' }}>
                    <div className="card-body">


                      <div className="articolo-paragrafi">
                        <div className="row">
                          <div className="">
                            <a id="avviso-par-competenze"> </a>
                            <h4>A chi è rivolto</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <p>
                              Possono presentare richiesta di iscrizione all’elenco, oggetto della presente Manifestazione di interesse, i seguenti operatori economici:
                              <br />
                              -        Cooperative sociali;<br />

                              -        Organizzazioni di Volontariato;<br />

                              -        Enti del Terzo Settore in possesso dei requisiti e delle capacità professionali previste dal presente avviso.<br />

                            </p>

                            <p>
                              <h4>Requisiti</h4>
                              Requisiti di cui all'art. 5 della Manifestazione di Interesse.


                            </p>

                            <p>
                              <h4>Scadenza</h4>


                              Scadenza presentazione domanda: 12/04/2023 ore 23:59
                            </p>

                            <div className="it-card-footer">
                              <span className="card-signature"></span>
                              <a className="btn btn-outline-primary btn-sm" target={'_blank'} style={{ margin: 20 }} href="https://www.comune.rende.cs.it/files/upload/PortaleAlboPretorioDocumento/allegati/avviso---%20(2).pdf">Avviso Pdf</a>

                              <span className="card-signature"></span>
                              <a className="btn btn-outline-primary btn-sm" style={{ margin: 20, background: 'aquamarine' }} href="/elencooperatorieconomici">Vai alla Domanda</a>

                            </div>



                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main >

      <a href="#" aria-hidden="true" data-attribute="back-to-top" className="back-to-top">
        <svg className="icon icon-light">
          <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-up"></use>
        </svg>
      </a>


    </>


  )
}