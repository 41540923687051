import React, { Component }  from 'react';
import AuthUser from './components/AuthUser';


import BOLayout from './layout/BoLayout';
function App() {
  const {getToken} = AuthUser();

  return <BOLayout isLogged={getToken()} />

  // if(!getToken()){
  //   return <BOLayout />
  // }
  // return (
  //     // <Auth />
  //     <BOLayout />
  // );
}

export default App;
