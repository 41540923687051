
import React, { Component, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useSearchParams } from "react-router-dom"
import { time } from 'console';

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Esito = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get("id")

    const stampa_id = (id: any) => {
        var nw = window.open();
        var data = new Date();
        var Hh, Mm, Ss, mm;
        Hh = data.getHours() + ":";
        Mm = data.getMinutes() + ":";
        Ss = data.getSeconds() + ":";
        mm = data.getMilliseconds() + ":";
        var html = '<h1> Domanda presentata con successo </h1>' + document.getElementById('ricevuta').innerHTML + '<style> .body{ width: 200px} </style>'
            + " <br/>Domanda acquisita alle ore :  " + Hh + Mm + Ss + mm
            + " <br/>token :  " + data.getTime();

        nw.document.write(html);
        nw.print();
        nw.close();
    }

    return (
        <>
            <section id="articolo-dettaglio-testo">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="card-wrapper card-space">
                            <div className="card card-bg"><div className="card-body">
                                <div className="articolo-paragrafi"><div className="row">
                                    <div  >
                                        <a id="articolo-par-competenze"> </a><h4>Domanda presentata con successo
                                        </h4>
                                    </div></div>
                                    <div className="row"><div >
                                        <p style={{ margin: 30 }} id='ricevuta'>
                                            Id domanda = {id}

                                        </p>
                                        <a className="btn btn-outline-primary btn-sm" style={{ margin: 30 }} href="/#articolo-par-servizi">Torna all'avviso</a>

                                        <a className="btn btn-outline-primary btn-sm" style={{ margin: 30 }}  href={"https://api.comune.rende.cs.it/storage/app/ricevute/"+id+".pdf"}>Stampa ricevuta</a>

                                    </div></div></div></div></div></div></div></div>
            </section>

        </>
    )
}

export default Esito;

function MD5(value: any) {
    throw new Error('Function not implemented.');
}
