import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';

const IpSelector = (props) => {
    const http = AuthUser().http;
    const  [ipaddresses,setipaddresses]= useState([]);

  const  handleChange =(evt) =>{
        
        if(props.onChange){
            props.onChange(evt.target.value);
        }
    }


    useEffect(()=> {
        http.get(`ipaddress`, {}).then(response => {
           
            setipaddresses(response.data );
                     
        });

    },[])

        return (
            <div className="form-group">
              <label>Indirizzo Ip</label> <br/><br/>
              {ipaddresses && <select className="form-control select3" defaultValue={props.defaultValue}  onChange={handleChange} style={props.style}>
                {ipaddresses.map((r,i)=>{
                    return (<option key={i} value={r.id}>{r.ip}</option>);
                })}
                               
              </select>}
            </div>
        )
    
}
export default IpSelector;
