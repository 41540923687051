import React, { useState, useEffect } from 'react';
import AuthUser from '../../../../components/AuthUser';
import CittaAutocomplete from '../../../../components/shared-component/CittaAutocomplete';
import HeaderContent from '../../../../components/shared-component/HeaderContent';
import Swal from 'sweetalert2';
import RoleSelector from '../../../../components/shared-component/RoleSelector';

import SignaturePad from 'react-signature-canvas';

const MyAccount = (props) => {
    const http = AuthUser().http;

    const [editRole, seteditRole] = useState(props.editRole == undefined ? false : props.editRole);
    const [showTitle, setshowTitle] = useState(props.showTitle);
    const [sigPad, setsigPad] = useState({});

    const [sedes, setsedes] = useState([]);
    const [profilo, setprofilo] = useState({});
    const [comune_nascita, setcomune_nascita] = useState({});
    const [comune_residenza, setcomune_residenza] = useState({});
    const [ruolo, setruolo] = useState({});
    const [password, setpassword] = useState({});
    const [inputFile, setinputFile] = useState({});

    useEffect(() => {

        const fetchDatasede = async () => {
            // get the data from the api
            const data = await http.get('sede').then((response) => {
                setsedes(response.data);
            })
            // convert data to json
            return data;
        }

        // call the function
        const result = fetchDatasede()
            // make sure to catch any error
            .catch(console.error);

        if (props.create) {

            const profilo = {
                nome: '',
                cognome: '',
                email: '',
                sesso: 'M',
                cf: '',
                stato_civile: '',
                cellulare: '',
                comune_nascita: '',
                data_nascita: '',
                comune_residenza: '',
                denominazione_indirizzo: '',
                indirizzo_residenza: '',
                civico_indirizzo: '',
                cittadinanza: '',
                cap_residenza: '',
                sede: '',
                statoattuale: '',
                matricola: '',
                modifier_user: '',
                alias: '',
                user_id: window.currentUser.id,
                role_id: 6,
                sede_id: null,
            };

            const fakeCitta = {
                id: 0,
                codice_catastale: '0000',
                nome: '',
                provincia: '',
                cap: '00000',
                siglaprovincia: '',
            }

            const fetchDataprof = async () => {
                // get the data from the api
                const data = await http.get(`userrole/6`).then((resposeRuolo) => {

                    setprofilo(profilo);
                    setcomune_nascita(fakeCitta);
                    setcomune_residenza(fakeCitta);
                    setruolo(resposeRuolo.data);

                });
                // convert data to json
                return data;
            }

            // call the function
            const resultp = fetchDataprof()
                // make sure to catch any error
                .catch(console.error);
        } else if (props.data !== undefined) {

            const p = { ...props.data };

            const cr = p.comune_residenza;
            const cn = p.comune_nascita;
            p.comune_nascita = cn == null ? 'H224' : cn.codice_catastale;
            p.comune_residenza = cr == null ? 'H224' : cr.codice_catastale;


            const fetchDataprof2 = async () => {
                // get the data from the api
                const data = await http.get(`userrole/${props.data.role_id}`).then((resposeRuolo) => {

                    setprofilo(p);
                    setcomune_nascita(cn);
                    setcomune_residenza(cr);
                    setruolo(resposeRuolo.data);

                });
                return data;
            }

            // call the function
            const resultp2 = fetchDataprof2()
                // make sure to catch any error
                .catch(console.error);
        }
        else {

            const fetchDataMyprof = async () => {
                // get the data from the api
                const data = await http.get('myprofile').then((response) => {

                    const p = { ...response.data };

                    const cr = p.comune_residenza;
                    const cn = p.comune_nascita;
                    p.comune_nascita = cn == null ? 'H224' : cn.codice_catastale;
                    p.comune_residenza = cr == null ? 'H224' : cr.codice_catastale;

                    http.get(`userrole/${response.data.role_id}`).then((resposeRuolo) => {

                        setprofilo(p);
                        setcomune_nascita(cn);
                        setcomune_residenza(cr);
                        setruolo(resposeRuolo.data);
                    });

                });
                return data;
            }

            // call the function
            const resultaMyprof = fetchDataMyprof()
                // make sure to catch any error
                .catch(console.error);
        }

    }, [])


    const saveAnagrafica = () => {
        const data = { ...profilo };

        if (sigPad !== null) {
            if (Object.keys(sigPad).length > 0) {
                data.firma = sigPad.getTrimmedCanvas().toDataURL('image/png');
            }
        }

        if (props.create) {
            http.post('profilo', { ...data }).then((response) => {

                Swal.fire({
                    position: 'top-end',
                    timer: 2000,
                    title: response.data.text,
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                })
            }).catch((reason, message) => {

                Swal.fire({
                    position: 'top-end',
                    title: reason,
                    icon: 'error',

                });
            });
            return;
        };

        http.post(`updateprofile/${profilo.id}`, { ...data }).then((response) => {
            Swal.fire({
                position: 'top-end',
                timer: 2000,
                title: response.data.text,
                icon: 'success',
                showConfirmButton: false,
                toast: true,
            })
        }).catch((reason) => {
            Swal.fire({
                position: 'top-end',
                title: reason,
                icon: 'error',
            });
        })
    }

    const salvaPassword = () => {
        const data = { password };

        http.post(`savepassword/${profilo.id}`, { ...data }).then((response) => {
            Swal.fire({
                position: 'top-end',
                timer: 2000,
                title: response.data.text,
                icon: 'success',
                showConfirmButton: false,
                toast: true,
            })
        }).catch((reason) => {
            Swal.fire({
                position: 'top-end',
                title: reason,
                icon: 'error',
            });
        })
    }


    const saveEmail = () => {
        saveAnagrafica();
    }

    const savePassword = () => {
        salvaPassword();
    }

    const updatepassword = (evt) => {


        setpassword(evt.target.value);

    };

    const updateInput = (evt) => {

        const p = profilo;
        p[evt.target.name] = evt.target.value;
        setprofilo(p);
    };

    const links = [
        { url: '/app/home', name: 'Home', active: false },
        { url: 'mioaccount', name: 'Il mio account', active: true },
    ]
    return (
        <>
            {profilo && <div>
                {showTitle && <HeaderContent title="Il mio account" subtitle="tutti i tuoi dati" links={links} />}
                {!showTitle && <p className="mt-1">&nbsp;</p>}
                <div className="row justify-content-md-center m-0" id="anagdivscroll">
                    <div className="col-md-10">
                        {/* Custom Tabs */}
                        <div className="card card-primary card-outline card-outline-tabs">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#tab_1" data-toggle="tab">Anagrafica</a>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" href="#tab_2" data-toggle="tab">Contatti</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#tab_6" data-toggle="tab">Dati CRM</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#tab_7" data-toggle="tab">Password</a></li>

                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tab_1">
                                        <div className="card card-primary">
                                            <div className="card-header with-border">
                                            </div>

                                            {/* /.card-header */}
                                            {/* form start */}
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="col-lg-offset-1 col-lg-10">
                                                            <div className="form-group">
                                                                <label className={profilo.nome ? 'active' : ''} htmlFor="nome">Nome</label>
                                                                <input type="text" className="form-control" id="nome" name="nome" defaultValue={profilo.nome} onBlur={updateInput} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className={profilo.cognome ? 'active' : ''} htmlFor="cognome">Cognome</label>
                                                                <input type="text" className="form-control" id="cognome" name="cognome" defaultValue={profilo.cognome} onBlur={updateInput} />
                                                            </div>
                                                            {/*
                                                                {comune_nascita && <CittaAutocomplete title="Comune di nascita" comune={`${comune_nascita.nome} (${comune_nascita.siglaprovincia})`}
                                                                    cap={comune_nascita.cap} codicecatastale={comune_nascita.codice_catastale}
                                                                    onChange={(data) => {

                                                                        const comune_nascita = comune_nascita;
                                                                        const p = profilo;
                                                                        p.comune_nascita = data.cod;
                                                                        const nome_p = /([a-z]+)\s\(([a-z]{2})\)$/gmi.exec(data.value);
                                                                        comune_nascita.nome = nome_p[1];
                                                                        comune_nascita.cap = data.cap;
                                                                        comune_nascita.siglaprovincia = nome_p[2];
                                                                        comune_nascita.codice_catastale = data.cod;
                                                                        setcomune_nascita(comune_nascita); 
                                                                        setprofilo(p );

                                                                    }} />}
                                                                    */}


                                                            <div className="form-group">
                                                                <label className={profilo.data_nascita ? 'active' : ''} htmlFor="data_nascita">Data di Nascita</label>
                                                                <input type="date" id="data_nascita" name="data_nascita" defaultValue={profilo.data_nascita} onBlur={updateInput} />
                                                            </div>


                                                            {/* radio */}
                                                            <div className="form-group">
                                                                <fieldset id="radiogroup">
                                                                    <legend>Sesso</legend>
                                                                    <div class="form-check form-check-inline">
                                                                        <input name="sesso" type="radio" id="m" required checked={profilo.sesso === 'M'} onChange={(e) => {
                                                                            const p = profilo;
                                                                            p.sesso = 'M';
                                                                            setprofilo(p);
                                                                        }} />
                                                                        <label for="m">M</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input name="sesso" type="radio" id="f" required checked={profilo.sesso === 'F'} onChange={(e) => {
                                                                            const p = profilo;
                                                                            p.sesso = 'F';
                                                                            setprofilo(p);
                                                                        }} />
                                                                        <label for="f">F</label>
                                                                    </div>
                                                                </fieldset>

                                                            </div>
                                                            {/**    <div className="form-group">
                                                                    <label  htmlFor="inputSuccess">Firma</label>
                                                                    <div style={{ position: 'relative' }}>
                                                                      <input type="file" style={{ display: 'none' }} ref={(ref) => inputFile = ref} name="firma-upload" onChange={(e) => {
                                                                            const risp = window.confirm('Vuoi aggiornare la tua firma ?');
                                                                            const f = new FormData();
                                                                            f.append('firmafile', e.target.files[0]);
                                                                            if (risp) {
                                                                                http.post(`profilo-update-firma/${profilo.id}`, f).then((response) => {
                                                                                    http.get('myprofile').then((response) => {

                                                                                        const p = { ...response.data };

                                                                                        const cr = p.comune_residenza;
                                                                                        const cn = p.comune_nascita;
                                                                                        p.comune_nascita = cn == null ? 'H224' : cn.codice_catastale;
                                                                                        p.comune_residenza = cr == null ? 'H224' : cr.codice_catastale;

                                                                                        http.get(`userrole/${response.data.role_id}`).then((resposeRuolo) => {

                                                                                            setprofilo(p); 
                                                                                            setcomune_nascita(cn); 
                                                                                            setcomune_residenza(cr); 
                                                                                            setruolo(resposeRuolo.data);

                                                                                        });

                                                                                    });
                                                                                });
                                                                            }
                                                                        }} />
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();

                                                                            inputFile.click();

                                                                        }} style={{ position: 'absolute', right: '25px', top: '0' }}><i className="fas fa-upload"></i></a>

                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            const risp =  window.confirm('Vuoi eliminare questa firma ?\n');
                                                                            if (risp) {

                                                                                if (sigPad !== null) {
                                                                                    if (Object.keys(sigPad).length > 0) {
                                                                                        sigPad.clear();
                                                                                    }
                                                                                }

                                                                                const p = profilo;
                                                                                p.firma = null;
                                                                                setprofilo(p);
                                                                            }

                                                                        }} style={{ position: 'absolute', right: '5px', top: '0' }}><i className="fas fa-times"></i></a>

                                                                        <hr style={{ position: 'absolute', bottom: '5px', width: '100%' }} />
                                                                        {profilo.firma && <img className="img-fluid" src={profilo.firma} style={{ paddingTop: '32px', paddingLeft: '5px' }} />}
                                                                        {!profilo.firma && <SignaturePad clearButton="true" ref={(ref) => { sigPad = ref }} canvasProps={{ className: 'sigCanvas' }} />}
                                                                       
                                                                    </div>
                                                                </div>  */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="col-lg-offset-1 col-lg-10">
                                                            <div className="form-group">
                                                                <div class="select-wrapper">
                                                                    <label htmlFor="cittadinanza">Nazionalità</label>
                                                                    <select className="form-control" id="cittadinanza" name="cittadinanza" onBlur={updateInput}>
                                                                        <option value="ITALIANA">Italiana</option>
                                                                        <option value="ESTERA">Estera</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/*
                                                                {comune_residenza && <CittaAutocomplete title="Comune di residenza" comune={`${comune_residenza.nome} (${comune_residenza.siglaprovincia})`}
                                                                    cap={comune_residenza.cap} codicecatastale={comune_residenza.codice_catastale} placeholder="Comune di residenza"
                                                                    onChange={(data) => {

                                                                        const comune_residenza = comune_residenza;
                                                                        const p = profilo;
                                                                        p.comune_residenza = data.cod;
                                                                        const nome_p = /([a-z]+)\s\(([a-z]{2})\)$/gmi.exec(data.value);
                                                                        comune_residenza.nome = nome_p[1];
                                                                        comune_residenza.cap = data.cap;
                                                                        comune_residenza.siglaprovincia = nome_p[2];
                                                                        comune_residenza.codice_catastale = data.cod;
                                                                        setcomune_residenza(comune_residenza);
                                                                         setprofilo(p);

                                                                    }} />}
                                                                    
                                                                */}
                                                            <div className="form-group">
                                                                <label className={profilo.indirizzo_residenza ? 'active' : ''} style={{ width: '100%', float: 'left' }} htmlFor="indirizzo_residenza">Indirizzo di
                                                                    Residenza</label>
                                                                <input style={{ width: '20%', float: 'left' }} type="text" className="form-control" name="denominazione_indirizzo" id="denominazione_indirizzo" defaultValue={profilo.denominazione_indirizzo} onBlur={updateInput} />
                                                                <input style={{ width: '60%', float: 'left' }} type="text" className="form-control" id="indirizzo_residenza" name="indirizzo_residenza" defaultValue={profilo.indirizzo_residenza} onBlur={updateInput} />
                                                                <input style={{ width: '20%' }} type="text" className="form-control" name="civico_indirizzo" id="civico_indirizzo" defaultValue={profilo.civico_indirizzo} onBlur={updateInput} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className={profilo.cf ? 'active' : ''} htmlFor="cf">Codice Fiscale</label>
                                                                <input type="text" className="form-control" id="cf" name="cf" defaultValue={profilo.cf} onBlur={updateInput} />
                                                            </div>
                                                            <div className="form-group">

                                                                <div class="select-wrapper">
                                                                    <label for="stato_civile">Stato civile</label>
                                                                    <select id="stato_civile" name="stato_civile" title="Scegli una opzione" onBlur={updateInput} required>
                                                                        <option selected value="">Scegli una opzione</option>
                                                                        <option value={1}>Celibe/Nubile</option>
                                                                        <option value={2}>Coniugato</option>
                                                                        <option value={3}>Vedovo</option>
                                                                        <option value={4}>Separato</option>
                                                                        <option value={5}>Divorziato</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/*     <div className="form-group">
                                                                <div class="select-wrapper">
                                                                    <label htmlFor="sede_id">Sede</label>
                                                                    <select className="form-control" id="sede_id" name="sede_id"  onBlur={updateInput} defaultValue={profilo.sede_id}>
                                                                        <option value="0">(Seleziona...)</option>
                                                                  {/*     {sedes.map((s, i) => {
                                                                            return (
                                                                                <option key={`sede-${i}`} value={s.id}>{s.nome}</option>
                                                                            )
                                                                        })}
                                                                   


                                                                    </select>
                                                                    </div>
                                                                </div>  */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary pull-right" onClick={saveAnagrafica}>Salva</button>
                                            </div>
                                            {/* /.card */}
                                        </div>
                                        {/* /.tab-pane */}
                                    </div>
                                    <div className="tab-pane" id="tab_2">
                                        <div className="card card-info">
                                            <div className="card-header with-border">
                                            </div>
                                            {/* /.card-header */}
                                            {/* form start */}

                                            <div className="card-body">
                                                <div className="col-lg-offset-1 col-lg-10">
                                                    <div className="form-group">
                                                        <label className={profilo.email ? 'active' : ''} htmlFor="email">Email</label><br />
                                                        <input style={{ width: '80%', float: 'left' }} type="email" id="email" name="email" defaultValue={profilo.email} onBlur={updateInput} />
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label className={profilo.cellulare ? 'active' : ''} htmlFor="cellulare">Cellulare</label>
                                                        <input type="tel" className="form-control" id="cellulare" name="cellulare" defaultValue={profilo.cellulare} onBlur={updateInput} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.card-footer */}
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-info pull-right" onClick={saveEmail}>Salva</button>
                                            </div>
                                            {/* /.card */}

                                        </div>
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="tab_6">
                                        <div className="card card-danger">
                                            <div className="card-header with-border">
                                            </div>

                                            <div className="card-body">
                                                <div className="col-lg-offset-1 col-lg-10">
                                                    <div className="form-group">

                                                        {ruolo && !editRole && <><label htmlFor="role">Ruolo</label><p>{ruolo.name}</p></>}
                                                        {ruolo && editRole &&
                                                            <>
                                                                <RoleSelector defaultValue={profilo.role_id} onChange={(r) => {
                                                                    const p = profilo;
                                                                    p.role_id = r;
                                                                    setprofilo(p);
                                                                }} />



                                                            </>}

                                                    </div>
                                                </div>
                                            </div>
                                            {ruolo && editRole && <div className="card-footer">
                                                <button type="submit" className="btn btn-info pull-right" onClick={saveEmail}>Salva</button>
                                            </div>}
                                            {/* <div className="card-footer">
                                                <button type="submit" id="salvaCRM" className="btn btn-danger pull-right">Salva</button>
                                            </div> */}
                                            {/* /.card */}

                                            {/* /.card-body */}
                                        </div>
                                        {/* /.tab-pane */}
                                    </div>

                                    <div className="tab-pane" id="tab_7">
                                        <div className="card card-info">
                                            <div className="card-header with-border">
                                            </div>
                                            {/* /.card-header */}
                                            {/* form start */}

                                            <div className="card-body">
                                                <div className="col-lg-offset-1 col-lg-10">
                                                    <div className="form-group">
                                                        <label className={profilo.email ? 'active' : ''} htmlFor="password">Nuova Password</label><br />
                                                        <input style={{ width: '80%', float: 'left' }} type="password" id="password" name="password" onBlur={updatepassword} />
                                                    </div>

                                                </div>
                                            </div>
                                            {/* /.card-footer */}
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-info pull-right" onClick={savePassword}>Salva</button>
                                            </div>
                                            {/* /.card */}

                                        </div>
                                    </div>

                                    <div id="tab-firma" >

                                    </div>
                                    <div className="tab-pane" id="tab_9">
                                        <div className="card card" style={{ borderTop: '3px solid fuchsia' }}>
                                            <div className="card-header with-border">
                                            </div>
                                            <form id="nonoform" method="POST" encType="multipart/form-data">
                                                <div className="card-body">
                                                    <div className="col-lg-offset-1 col-lg-10">
                                                        <div className="form-group">
                                                            <input type="hidden" className="form-control" id="idmod" name="idmod" defaultValue={profilo.id} />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="hidden" className="form-control" name="role_mod" id="role_mod" defaultValue={profilo.role_id} />
                                                        </div>
                                                        <label>Ultime Modifiche</label><br />
                                                        <table className="table table-bordered table-striped" id="dataTableBuilderModifiche">
                                                            <thead>
                                                                <tr>
                                                                    <th>Iban</th>
                                                                    <th>Cellulare</th>
                                                                    <th>Email</th>
                                                                    <th>Bonus Renzi</th>
                                                                    <th>Data Modifica</th>
                                                                    <th>Utente</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                </div>
                                                {/* /.card */}
                                            </form>
                                            {/* /.card-body */}
                                        </div>
                                        {/* /.tab-pane */}
                                    </div>
                                    {/* /.tab-content */}
                                </div>
                            </div>

                        </div>
                        {/* nav-tabs-custom */}
                    </div>
                    {/* /.col */}
                </div>


            </div>

            }
        </>
    )

}
export default MyAccount;