import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';

const RoleSelector = (props) => {
    const http = AuthUser().http;

    const  [roles,setroles]= useState([]);

   

    useEffect(()=> {
        http.get(`role`, {}).then(response => {

            setroles( response.data);
        });

    },[]);

   

    const  handleChange = (evt) => {
        if (props.onChange) {
            props.onChange(evt.target.value);
        }
    }

   
        return (
            <div className="form-group">
                <label>Ruolo</label><br/><br/>
                {roles && <select className="form-control select2" value={props.defaultValue} onChange={handleChange} style={props.style}>
                    {roles.map((r, i) => {
                        return (<option key={i} value={r.id}>{r.name}</option>);
                    })}


                </select>}
            </div>
        )
    
}

export default RoleSelector;
