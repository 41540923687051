import React, { Component } from 'react'
import Cookiebar from './Cookiebar'

export default class Footer extends Component {
    render() {
        return (
<>
<footer className="it-footer mt-auto" style={{flexShrink: 0}} >
  <div className="it-footer-main">
    <div className="container">
      <section>
        <div className="row clearfix">
          <div className="col-sm-12">
            <div className="it-brand-wrapper">
              <a href="#">
                <svg className="icon">
                  <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-pa"></use>
                </svg>
                <div className="it-brand-text">
                  <h2 className="no_toc">Comune di Rende</h2>
                  
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  </div>
  <div className="it-footer-small-prints clearfix">
    <div className="container">
      <h3 className="sr-only">Sezione Link Utili</h3>
      <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">

        <li className="list-inline-item">
          <a href="https://www.comune.rende.cs.it/cookie-policy/" title="cookie-privacy">Cookie Policy</a>
        </li>
        <li className="list-inline-item">
          <a href="https://www.comune.rende.cs.it/privacy-policy/" title="Privacy-Policy">Privacy Policy</a>
        </li>
      
      </ul>
    </div>
  </div>
</footer>
</>

        )
    }
}
