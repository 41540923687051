import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";



const cron = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('This will run after 1 second!')

            axios.get(`https://api.comune.rende.cs.it/api/cron/1983458`).then((response) => {
                console.log(response);

                window.location.reload();
            }).catch((err) => {
                console.log(err);
                window.location.reload();
            }
            )

        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('This will run after 1 second!')

            window.location.reload();

        }, 35000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>

            cron start
        </>

    )
}

export default cron;