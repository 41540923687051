import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const links = [
    { url: '/app/home', name: 'Home', active: false },
    { url: '/app/sede', name: 'Sede', active: false },
    { url: '/app/sede/nuovosede', name: 'Nuovo Sede', active: true },
]
const NuovoSede = ()=>  {

    const http = AuthUser().http;
    const  [sedes,setsedes]= useState([]);

    useEffect(()=> {
        http.get(`sede`, {}).then(response => {
           
            setsedes(response.data );
                     
        });

    },[])


const  itemDelete = (id) => {
        http.delete(`sede/${id}`).then((response) => {

        }).catch((reason) => {
            http.get('sede').then((response) => {
                setsedes(response.data );
            })
        })

    }



        return (
            <div>
                <HeaderContent title="Gestione Sede" subtitle="crea e modifica le Sedi Aziendali" links={links} />
                <div>
                    <section className="content">
                        {/* Default box */}
                        <div className="card">
                            <div className="card-header with-border">
                                <h3 className="card-title" />
                                <Link to="/app/sede/create" className="col-lg-offset-5 btn btn-success">Nuovo Sede</Link>
                                <div className="card-tools pull-right">
                                    <button type="button" className="btn btn-box-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fa fa-minus" /></button>
                                    <button type="button" className="btn btn-box-tool" data-card-widget="remove" title="Remove">
                                        <i className="fa fa-times" /></button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Sede salvati</h3>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="card-body">

                                        <table id="tablesede" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Id Sede</th>
                                                    <th>Nome Sede</th>
                                                    <th>Latitudine</th>
                                                    <th>Longitudine</th>
                                                    <th>Ip Pubblico</th>
                                                    <th>...</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sedes.map((m, i) => {
                                                    return (
                                                        <tr key={m.id}>
                                                            <td>{m.id}</td>
                                                            <td>{m.nome}</td>
                                                            <td>{m.latitudine}</td>
                                                            <td>{m.longitudine}</td>
                                                            <td>{m.ippubblico}</td>
                                                            <td>
                                                                <Link to={`/app/sede/edit/${m.id}`}><i className="fas fa-edit mr-2"></i></Link>
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    Swal.fire({
                                                                        title: 'Vuoi eliminare la sede ?',
                                                                        showDenyButton: false,
                                                                        showCancelButton: true,
                                                                        confirmButtonText: `OK`,

                                                                    }).then((result) => {
                                                                        /* Read more about isConfirmed, isDenied below */
                                                                        if (result.isConfirmed) {
                                                                            itemDelete(m.id);
                                                                        }
                                                                    })

                                                                }}><i className="fas fa-trash" /></a></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.box-body */}
                            <div className="card-footer">
                            </div>
                            {/* /.box-footer*/}
                        </div>
                        {/* /.box */}
                    </section>
                    {/* /.content */}
                </div>



            </div>
        )
    
}
export default NuovoSede;
