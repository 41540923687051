import React, { Component } from 'react';
export default function Avvisoalbo() {
  return (
    <>

      <main>
        <div className="container my-4">

          <section id="intro">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-6 col-md-8">
                  <div className="titolo-sezione">
                    <h2>Avviso </h2>
                    <p>
                      Riconoscimento di un contributo a rimborso totale o parziale delle spese sostenute dalle famiglie
                      per la frequenza del/della proprio/a figlio/a minore (da 3 a 17 anni), residente nel Comune di Rende,
                      di attività presso centri estivi, servizi socio/educativi territoriali, centri con funzione educativa e
                      ricreativa, comprese quelle finalizzate alla promozione dello studio delle materie STEM,
                      relativamente al periodo 01 giugno – 31 dicembre 2022.
                    </p>

                  </div>
                </div>
                <div className="offset-lg-1 col-lg-3 col-md-4">
                  <aside id="argomenti-sezione">
                    <div className="argomenti">

                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="row" style={{ height: 20 }}>
            </div>
          </section>
          <section id="articolo-dettaglio-testo" >
            <div className="row">
              <div className="col-12 col-lg-12">
                {/*start card*/}
                <div className="card-wrapper card-space">
                  <div className="card card-bg" style={{ background: '#f2f7fc' }}>
                    <div className="card-body">


                      <div className="articolo-paragrafi">
                        <div className="row">
                          <div className="">
                            <a id="avviso-par-competenze"> </a>
                            <h4>A chi è rivolto</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="">
                            <p>
                              Il presente Avviso pubblico è finalizzato all’individuazione delle famiglie beneficiarie del
                              contributo per la frequenza di attività, destinate ai minori dai 3 ai 17 anni, organizzate dai centri
                              estivi, dai servizi socioeducativi territoriali e dai centri con funzione educativa e ricreativa,
                              comprese quelle finalizzate alla promozione dello studio delle materie STEM, nel periodo 01
                              giugno 2022 – 31 dicembre 2022.      </p>

                            <p>
                              <h4>Requisiti</h4>
                              I. Frequenza del minore ad uno dei servizi in epigrafe; <br />
                              II. Residenza del minore nel Comune di Rende dalla data di inizio di fruizione del servizio; <br />
                              III. ISEE 2023 IN CORSO DI VALIDITÀ OPPURE ISEE CORRENTE, NON SUPERIORE
                              ad € 65.000,00.  <br />

                              IV. Non beneficiare, per lo stesso periodo di riferimento e per il medesimo minore per cui si
                              chiede il rimborso  <br />
                              V. Possesso di documentazione valida attestante la partecipazione del minore ad una delle attività indicate nell'avviso e la relativa spesa sostenuta<br />.

                            </p>
                            <p>
                              <h4>Termine di Scadenza</h4>
                              06 Marzo 2023 ore 18:00
                            </p>

                            <div className="it-card-footer">
                              <span className="card-signature"></span>

                              <div className="row">
                                <div className="col-sm">
                                  <a className="btn btn-outline-primary btn-sm" target={'_blank'} style={{ margin: 20 }} href="https://www.comune.rende.cs.it/files/upload/PortaleAlboPretorioDocumento/allegati/45a36d27e0d64749ac3b8ae546512591_avviso-.pdf">Proroga</a>

                                  <a className="btn btn-outline-primary btn-sm" target={'_blank'} style={{ margin: 20 }} href="https://www.comune.rende.cs.it/files/upload/PortaleAlboPretorioDocumento/allegati/ccb2785a843b47f49494fdeb50c887e8_avviso%20pubblico.pdf">Avviso Pdf</a>

                                </div>
                                <div className="col-sm">
                                  <a className="btn btn-outline-primary btn-sm" target={'_blank'} style={{ margin: 20 }} href="/Allegato 1- Dichiarazione sostitutiva di certificazione.doc">Dichiarazione sostitutiva di certificazione</a>

                                  <a className="btn btn-outline-primary btn-sm" style={{ margin: 20 }} href="/faq">FAQ</a>
                                  <a className="btn btn-outline-primary btn-sm" style={{ margin: 20, background: 'aquamarine' }} href="/domandarimborsonew">Vai alla Domanda</a>

                                </div>
                                <div className="col-sm">

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main >

      <a href="#" aria-hidden="true" data-attribute="back-to-top" className="back-to-top">
        <svg className="icon icon-light">
          <use xlinkHref="/bootstrap-italia/dist/svg/sprite.svg#it-arrow-up"></use>
        </svg>
      </a>


    </>


  )
}