import React, { Component }  from 'react';
export default function Domandafornitori() {
    return (
        <center>
            <form method="post" id="form_domanda" action="buonispesa_domanda.php" className="form-horizontal" encType="multipart/form-data">
                <div className="form-group">
                    <div className="col-sm-5 col-sm-offset-1" >
                        <table>
                            <tbody><tr>
                                <td>
                                    <ol className="breadcrumb" style={{ backgroundColor: 'white' }}>

                                        <li className="active">Nuova domanda</li>
                                    </ol>
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                </div>
                <div class="form-group"><div class="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Comune di Riferimento</div></div>

                <div className="form-group">
                    <div class="select-wrapper">
                        <label for="comunedomanda">Selezionare il Comune per il quale si vuole fare domanda</label>
                        <select id="comunedomanda">
                            <option selected="" value="">Scegli un'opzione</option>
                            <option value="1">RENDE</option>
                            <option value="2">CASTROLIBERO</option>
                        </select>
                    </div>
                </div>

                <div id="div_domanda">

                    <div class="form-group"><div class="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dati Fornitore </div></div>
                    <div class="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="nome">Il/la sottoscritto/a</label>
                                <input type="text" className="form-control" id="nome" />
                            </div>
                        </div>

                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="cf">Codice Fiscale</label>
                                <input type="text" className="form-control" id="cf" />
                            </div>
                        </div>

                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="comunenascita">Nato/a a</label>
                                <input type="text" className="form-control" id="comunenascita" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-sm">
                            <div class="form-group">
                                <label class="active" for="nascita">Nato/a il (gg/mm/aaaa)</label>
                                <input type="date" id="nascita" name="nascita" />

                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="comuneresidenza">Residente in</label>
                                <input type="text" className="form-control" id="comuneresidenza" />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="indirizzoresidenza">Indirizzo di residenza</label>
                                <input type="text" className="form-control" id="indirizzoresidenza" />
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="cellulare">Cellulare</label>
                                <input type="text" className="form-control" id="cellulare" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="email">e-mail</label>
                                <input type="text" className="form-control" id="email" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="pec">PEC (facoltativa)</label>
                                <input type="text" className="form-control" id="pec" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div className="col-sm">

                            <div className="form-upload">
                                <label htmlFor="upload" className="active">Documento d'identità</label>
                                <input type="file" name="upload" id="upload" multiple="multiple" required className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-0" style={{}} />
                                <div className="form-feedback just-validate-error-label" id="upload-error-0"></div>
                            </div>

                        </div>
                        <div className="col-sm">
                            <label className="active">In qualità di</label>
                            <div className="form-group">
                                <fieldset id="radiogroup">
                                    <div className="form-check form-check-inline">
                                        <input name="gruppo2" type="radio" id="radio1" required className="is-invalid" aria-describedby="radio1-error-0" aria-invalid="true" style={{ color: 'rgb(184, 17, 17)', border: '1px solid rgb(184, 17, 17)' }} />
                                        <label htmlFor="radio1">Titolare</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input name="gruppo2" type="radio" id="radio2" required className="is-invalid" aria-describedby="radio2-error-0" aria-invalid="true" style={{ color: 'rgb(184, 17, 17)', border: '1px solid rgb(184, 17, 17)' }} />
                                        <label htmlFor="radio2">Legale Rappresentante</label>
                                    </div>
                                    <div className="form-feedback just-validate-error-label" id="radiogroup-error-0"></div></fieldset>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="rs">dell'esercizio commerciale (Denominazione)</label>
                                <input type="text" className="form-control" id="rs" />
                            </div>
                        </div>
                    </div>

                    <div class="row">



                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="comunesedelegale">Con sede legale in</label>
                                <input type="text" className="form-control" id="comunesedelegale" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="indirizzosedelegale">Indirizzo</label>
                                <input type="text" className="form-control" id="indirizzosedelegale" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="piva">Partita Iva</label>
                                <input type="text" className="form-control" id="piva" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="cfazienda">Codice Fiscale</label>
                                <input type="text" className="form-control" id="cfazienda" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="comuneccia">Iscritta presso la C.C.I.A. di
                                </label>
                                <input type="text" className="form-control" id="comuneccia" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="numccia">Numero iscrizione C.C.I.A.
                                </label>
                                <input type="text" className="form-control" id="numccia" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div className="col-sm">

                            <div class="form-group">
                                <label class="active" for="dataccia">Data iscrizione C.C.I.A.
                                </label>
                                <input type="date" id="dataccia" name="dataccia" />

                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="attivitaccia">Attività iscrizione C.C.I.A.

                                </label>
                                <input type="text" className="form-control" id="attivitaccia" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="telazienda">Telefono.
                                </label>
                                <input type="text" className="form-control" id="telazienda" />
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="emailazienda">e-mail

                                </label>
                                <input type="text" className="form-control" id="emailazienda" />
                            </div>

                        </div>
                        <div className="col-sm">

                            <div className="form-group">
                                <label htmlFor="pecazienda">PEC
                                </label>
                                <input type="text" className="form-control" id="pecazienda" />
                            </div>
                        </div>
                        <div className="col-sm">
                        </div>
                    </div>


                    <div class="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>sede/i di utilizzo dei buoni spesa come in tabella riportato</div>

                    <div class="row">
                        <table id="table_elenco" name="table_elenco" data-toggle="table" className="table table-hover table-condensed">
                            <thead>
                                <tr className="default">
                                    <th style={{ width: '20%' }} className="intestazioneTabella text-center text-info">Denominazione sede di utilizzo</th>
                                    <th style={{ width: '15%' }} className="intestazioneTabella text-center text-info">Località</th>
                                    <th style={{ width: '15%' }} className="intestazioneTabella text-center text-info">Comune</th>
                                    <th style={{ width: '15%' }} className="intestazioneTabella text-center text-info">Indirizzo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="text" name="tdenominazione1" id="tdenominazione1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tlocalita1" id="tlocalita1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tcomune1" id="tcomune1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tindirizzo1" id="tindirizzo1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                </tr><tr>
                                    <td>
                                        <input type="text" name="tdenominazione2" id="tdenominazione1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tlocalita2" id="tlocalita1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tcomune2" id="tcomune1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tindirizzo2" id="tindirizzo1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                </tr><tr>
                                    <td>
                                        <input type="text" name="tdenominazione3" id="tdenominazione1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tlocalita3" id="tlocalita1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tcomune3" id="tcomune1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tindirizzo3" id="tindirizzo1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                </tr><tr>
                                    <td>
                                        <input type="text" name="tdenominazione4" id="tdenominazione1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tlocalita4" id="tlocalita1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tcomune4" id="tcomune1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tindirizzo4" id="tindirizzo1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                </tr><tr>
                                    <td>
                                        <input type="text" name="tdenominazione5" id="tdenominazione1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tlocalita5" id="tlocalita1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tcomune5" id="tcomune1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                    <td>
                                        <input type="text" name="tindirizzo5" id="tindirizzo1" className="class_domanda form-control input-sm" aria-describedby="sizing-addon1" />
                                    </td>
                                </tr>								</tbody>
                        </table>

                    </div>



                    <div class="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Comunicazione codice IBAN al fine del rimborso dei buoni</div>

                    <br />
                    <div className="row">

                        <div className="col-sm">
                            <div className="form-group">
                                <label htmlFor="iban">IBAN per l'accredito su conto corrente dedicato postale o bancario
                                </label>
                                <input type="text" className="form-control" id="iban" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group"><div class="col-sm-12 text-center white bg-primary" style={{ color: 'white' }}>Dichiara che</div></div>
                    <div className="form-group">
                        <fieldset id="checkgroup">
                            <div className="row">
                                <div className="form-check form-check" >
                                    <input id="dichiara1" type="checkbox" required className="just-validate-success-field" aria-invalid="false" />
                                    <label htmlFor="dichiara1">Dichiara la propria volonta' di aderire all'iniziativa per la  fornitura prodotti alimentari e generi di prima necessita' attraverso buoni spesa nominali DPCM emergenza COVID-19 *
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-check form-check">
                                        <input id="dichiara2" type="checkbox" required className="just-validate-success-field" aria-invalid="false" data-focus-mouse="false" />
                                        <label htmlFor="dichiara2">di attuare condizioni favorevoli di sconto ai beneficiari dei buoni spesa con uno sconto (indicare la percentuale) *
                                        </label>
                                    </div>

                                </div>

                                <div className="col-sm">
                                    <label htmlFor="percentualesconto">inserire valore richiesto</label>
                                    <input type="text" className="form-control" id="percentualesconto" name="percentualesconto" />
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-check form-check">
                                        <input id="dichiara3" type="checkbox" required className="just-validate-success-field" aria-invalid="false" data-focus-mouse="false" />
                                        <label htmlFor="dichiara3">che per il rimborso, corredato dai documenti commerciali (ex scontrini) e dai buoni utilizzati dai beneficiari emettera' (indicare una modalita') *
                                        </label>
                                    </div>

                                </div>

                                <div className="col-sm">
                                    <label htmlFor="modalita">inserire valore richiesto</label>
                                    <input type="text" className="form-control" id="modalita" name="modalita" />
                                </div>

                            </div>
                
                            <div className="row">
                                <div className="form-check form-check">
                                    <input id="dichiara4" type="checkbox" required className="just-validate-success-field" aria-invalid="false" data-focus-mouse="false" />
                                    <label htmlFor="dichiara4">di aver preso visione della Manifestazione di Interesse e di accettarne integralmente il contenuto *</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-check form-check">
                                    <input id="dichiara5" type="checkbox" required className="just-validate-success-field" aria-invalid="false" data-focus-mouse="false" />
                                    <label htmlFor="dichiara5">di essere a conoscenza che i buoni spesa potranno essere utilizzati dai cittadini entro la data di scadenza prevista dai buoni medesimi * </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-check form-check">
                                    <input id="dichiara6" type="checkbox" required className="just-validate-success-field" aria-invalid="false" data-focus-mouse="false" />
                                    <label htmlFor="dichiara6">di aver preso visione dell'Informativa sul trattamento dei dati personali ai sensi del Regolamento UE 679/2016 (GDPR) *   </label>
                                </div>
                            </div>

                        </fieldset>


                    </div>

                </div>

                <button name="_conferma" id="_conferma" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}>Conferma</button>


            </form >
        </center >

    )
}