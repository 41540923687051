import React, { useState, useEffect } from 'react';
import AuthUser from '../AuthUser';
import { Link } from 'react-router-dom';
const Aside = (props) => {

    const http = AuthUser().http;

    const [username, setUsername] = useState(null);
    const [activeMenu, setActiveMenu] = useState({});
    const [menustructure, setMenustructure] = useState([]);

    useEffect(() => {
        http.get('/menurender', {}).then(response => {

            setMenustructure(response.data.menu);
            setUsername(response.data.userdata);

        });

    }, [])

    const activateMenu = ({ menuid, submenuid }) => {
        setActiveMenu({ menuid: menuid, subid: submenuid });

    }

    return (
        <aside >

            {/* Sidebar */}

            {/* Sidebar Menu */}
            <nav className="inline-menu" style={{ width: 250 }}>
                <div className="link-list-wrapper">
                    <ul className="link-list">

                        {
                            menustructure.map((m, i) => {

                                if (m.menu.has_submenus == 1) {
                                    return (
                                        <li key={`menu${m.menu.id}`}><a className="list-item large medium right-icon" href={`#collapse-${m.menu.id}`} data-toggle="collapse" aria-expanded="false" aria-controls={`collapse-${m.menu.id}`}>
                                            <span>{m.menu.name}</span><svg className="icon icon-xs icon-primary right"><use href="#"></use></svg></a>

                                            <ul className="link-sublist collapse" id={`collapse-${m.menu.id}`}>
                                                {m.submenu.map((s, ii) => {

                                                    return (
                                                        <li key={`s${s.name}-${ii}`}>
                                                            <Link className="list-item" to={s.function}><span>{s.name}</span></Link>

                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    );
                                } else {

                                    return (
                                        <li>
                                            <Link className="list-item" to={m.submenu[0].function}><span>{m.menu.name}</span></Link>

                                        </li>
                                    );
                                }
                            })
                        }
                    </ul>
                </div>
            </nav>
            {/* /.sidebar-menu */}


        </aside >

    )
}


export default Aside;

