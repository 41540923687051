import React, { useState, useEffect } from 'react';
import AuthUser from '../../../components/AuthUser';
import HeaderContent from '../../../components/shared-component/HeaderContent';
import { Link ,useParams} from 'react-router-dom';

import Swal from 'sweetalert2';
const links = [
    { url: '/app/home', name: 'Home', active: false },
    { url: '/app/role', name: 'Ruoli', active: false },
    { url: '/app/role/elencoruoli', name: 'Ruoli', active: true },
]

const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})


const ElencoRuoli = (props) => {

    const http = AuthUser().http;
    const  [roles,setroles]= useState([]);

    const params = useParams();
    console.log(params);
    const newinsert=1;
    if(params.id==0)newinsert=0;

    useEffect(()=> {
        http.get(`role`, {}).then(response => {
           
            setroles(response.data );
                     
        });

    },[])

 const   itemDelete = (id) => {
        http.delete(`role/${id}`).then((response)=>{
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });
                setroles(response.data.roles );
             
            }
        })
    }

        return (
            <div>
                <HeaderContent title="Gestione Ruoli" subtitle="crea e modifica i ruoli di accesso" links={links} />
                <div>
                    <section className="content">
                        {/* Default box */}
                        <div className="card">
                           
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-header">
                                       
                                        <div class="row">
                                    <div class="col-sm"> 
                                    <h3 className="card-title">Ruoli salvati</h3>
                                    </div>
                                    <div class="col-sm"> 
                                    <Link to="/app/role/create/0" className="col-lg-offset-5 btn btn-success">Nuovo Ruolo</Link>
                                    </div>
                                    </div>

                                    </div>
                                    {/* /.box-header */}
                                    <div className="card-body">

                                        <table id="tablemenu" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Id Ruolo</th>
                                                    <th>Nome Ruolo</th>
                                                    <th>Ruolo</th>
                                                    <th>Azioni</th>
                                                 
                                                </tr>
                                            </thead>
                                           <tbody>
                                                {roles.map((m, i) => {
                                                    return (
                                                        <tr key={m.id}>
                                                            <td>{m.id}</td>
                                                            <td>{m.name}</td>
                                                            <td>{m.ruolo}</td>
                                                            <td><span><Link to={`/app/role/create/${m.id}`}>
                                                            <svg className="icon"><use href="/bootstrap-italia/dist/svg/sprite.svg#it-pencil" /></svg>   
                                                            </Link>
                                                           </span> <span><Link to="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                Swal.fire({
                                                                    title: 'Vuoi eliminare il ruolo ?',
                                                                    showDenyButton: false,
                                                                    showCancelButton: true,
                                                                    confirmButtonText: `OK`,
                                                                    
                                                                  }).then((result) => {
                                                                    /* Read more about isConfirmed, isDenied below */
                                                                    if (result.isConfirmed) {
                                                                        itemDelete(m.id);
                                                                    } 
                                                                  })
                                                               
                                                            }}> <svg className="icon"><use href="/bootstrap-italia/dist/svg/sprite.svg#it-delete" /></svg></Link></span>

                                                           <div>
  
</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.box-body */}
                            <div className="card-footer">
                            </div>
                            {/* /.box-footer*/}
                        </div>
                        {/* /.box */}
                    </section>
                    {/* /.content */}
                </div>



            </div>
        )
    
}
export default ElencoRuoli;