import axios from "axios";
import { ErrorMessage, Field, FieldArray, Formik, FormikConsumer, FormikProps, useFormik } from "formik";
import React, { Component, useEffect, useState } from 'react';
import internal from "stream";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { API_URL_LARAVEL } from "../config/config";
import InputField from "./shared-component/Input/InputField";


const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Error = () => {

    const aprimodal = () => {

        document.getElementById('nuovafaq').style.display = "inline";
    }

    const inviadomanda = () => {

        const fd = new FormData();

        // @ts-ignore
        fd.append('email', document.getElementById('email').value);
        // @ts-ignore
        fd.append('testodomanda', 'Segnalazione Errore : ' + document.getElementById('testodomanda').value);

        axios.post(API_URL_LARAVEL + '/faq', fd
        ).then((response) => {

            console.log(response);
            if (response.data.success) {
                Toast.fire({
                    title: response.data.text,
                    icon: 'success'
                });

                document.getElementById('resultfaq').innerHTML = response.data.text;
                document.getElementById('buttfaq').style.display = "none";



            }
        }).catch((err) => {
            console.log(err);
            alert('Errore imprevisto');
        });

        // document.getElementById('nuovafaq').style.display = "inline";
    }

    return (
        <>
            <section id="articolo-dettaglio-testo" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%'
            }}>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="card-wrapper card-space">
                            <div className="card card-bg"><div className="card-body">
                                <div className="articolo-paragrafi"><div className="row">
                                    <div ><a id="articolo-par-competenze"> </a><h4>Errore Imprevisto
                                    </h4>
                                    </div></div>
                                    <div className="row"><div >
                                        <p>
                                            <strong>   Errore Imprevisto </strong> <br />
                                            Errori possibili:<br />
                                            Se stava compilando un form con allegati riprovi rinominando i file con nomi più corti e senza caratteri speciali.
                                            <br />
                                            Per altri errori può segnalarli.
                                            <br /><br />
                                            Ci scusiamo per il disagio
                                            <br />

                                            <br />
                                            <div>
                                                {/* Button trigger modal */}
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        aprimodal();
                                                    }}
                                                >Invia Segnalazione di errore</button>
                                                {/* Modal */}
                                                <div id="nuovafaq" style={{ 'display': 'none' }}>
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h2 className="modal-title h5 no_toc" id="myInput">Invia Segnalazione</h2>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor="email" >Inserisci Email</label>
                                                                        <input type="email" className="form-control" id="email" required data-focus-mouse="false" aria-invalid="false" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div className="form-group">
                                                                        <label htmlFor="testodomanda" >Testo descrizione errore.</label>
                                                                        <textarea id="testodomanda" rows={3} data-focus-mouse="false" defaultValue={""} />
                                                                    </div>
                                                                </div>

                                                                <button id="buttfaq" type="button" className="btn btn-primary"
                                                                    onClick={() => {
                                                                        inviadomanda();
                                                                    }}
                                                                >Invia Errore</button>
                                                            </div>
                                                            <p style={{ padding: 9 }}>*descrizione errore. ( browser utilizzato, caratteristiche computer e passaggi eseguiti)
                                                            </p>

                                                            <h5 style={{ padding: 15 }} id="resultfaq"></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </p>
                                    </div></div></div></div></div></div></div></div>
            </section>

        </>
    )
}

export default Error;